/**
 * Fabrication Components
 * @format
 */

import { Formik } from "formik";
import * as Yup from "yup";
import { FabricationFields } from "../../../constants";
import { useDispatch } from "react-redux";
import TextArea from "../../../components/textArea";
import { useEffect, useState } from "react";
import { updateBeneficiaryJourneyByBeneficiaryId } from "../slice";
import CustomButton from "../../../components/customButtion";

export function FabricationComponent(props) {
  const dispatch = useDispatch();
  const { beneficiary_id, status, stageIds, fabrication_data } = props;

  const [pending, setPending] = useState(true);
  const [complete, setComplete] = useState(false);

  const initialValues = {
    [FabricationFields.COMPLETIONNOTES]: "",
  };
  const [initialObject, setInitialObject] = useState(initialValues);

  const validationSchema = Yup.object().shape({
    [FabricationFields.COMPLETIONNOTES]: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (fabrication_data) {
      if (fabrication_data?.data?.length === 0) {
        setComplete(true);
        setPending(false);
      } else if (fabrication_data?.data?.length > 0) {
        setInitialObject(fabrication_data?.data?.[0]);
        // setComplete(true);
        setPending(false);
      }
    }
  }, [fabrication_data]);

  return (
    <>
      <div className={``}>
        <Formik
          initialValues={initialObject}
          // validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            if (values?.buttonName === "Start") {
              const updatedValues = {
                beneficiary_id,
                beneficiary_status: {
                  beneficiary_stage_id: stageIds?.fabrication,
                  status_id: values.status_id,
                  data: [],
                },
              };

              // console.log({ values, updatedValues });
              dispatch(updateBeneficiaryJourneyByBeneficiaryId(updatedValues));
            } else if (values?.buttonName === "Completed") {
              const updatedValues = {
                beneficiary_id,
                beneficiary_status: {
                  beneficiary_stage_id: stageIds?.fabrication,
                  status_id: values.status_id,
                  data: [
                    {
                      [FabricationFields.COMPLETIONNOTES]:
                        values[FabricationFields.COMPLETIONNOTES],
                    },
                  ],
                },
              };

              dispatch(updateBeneficiaryJourneyByBeneficiaryId(updatedValues));
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            >
              <div className="flex flex-col space-y-4 mb-4">
                <div className="w-full">
                  {pending ? (
                    <>
                      <CustomButton
                        name="Start"
                        type="submit"
                        loading={false}
                        disabled={complete}
                        onClick={(e) => {
                          // e.preventDefault();
                          setFieldValue("buttonName", "Start");
                          setFieldValue(
                            "status_id",
                            status?.fabrication_in_progress
                          );
                        }}
                        className={`text-white  flex flex-row items-center justify-center space-x-2 ${
                          complete ? "bg-[#C1C1C1]" : "bg-WildWatermelon-button"
                        }`}
                        icon={
                          <span className="me-1">
                            {" "}
                            <svg
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 2.75V0.5L3 3.5L6 6.5V4.25C8.4825 4.25 10.5 6.2675 10.5 8.75C10.5 10.9775 8.8725 12.8225 6.75 13.1825V14.6975C9.7125 14.33 12 11.81 12 8.75C12 5.435 9.315 2.75 6 2.75ZM1.5 8.75C1.5 7.5125 2.0025 6.3875 2.82 5.57L1.755 4.505C1.198 5.06263 0.756312 5.72453 0.455183 6.4529C0.154055 7.18127 -0.000617915 7.96184 1.85519e-06 8.75C1.85519e-06 11.81 2.2875 14.33 5.25 14.6975V13.1825C3.1275 12.8225 1.5 10.9775 1.5 8.75Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-sm font-bold">Completion Notes</label>
                  <TextArea
                    name={FabricationFields.COMPLETIONNOTES}
                    id={FabricationFields.COMPLETIONNOTES}
                    value={values[FabricationFields.COMPLETIONNOTES]}
                    placeHolder={""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={"text"}
                    className="h-[79px] py-1"
                    disabled={!complete}
                  />
                  {touched[FabricationFields.COMPLETIONNOTES] &&
                    errors[FabricationFields.COMPLETIONNOTES] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[FabricationFields.COMPLETIONNOTES]}
                      </div>
                    )}
                </div>
                <div className="w-full">
                  <CustomButton
                    name="Completed"
                    type="submit"
                    loading={false}
                    disabled={!complete}
                    onClick={(e) => {
                      // e.preventDefault();
                      setFieldValue("buttonName", "Completed");
                      setFieldValue("status_id", status?.fabrication_completed);
                    }}
                    className={`text-white px-4 py-1 rounded-md font-bold  h-full flex flex-row items-center justify-center space-x-2 ${
                      complete ? "bg-WildWatermelon-button" : "bg-[#C1C1C1]"
                    }`}
                    icon={
                      <span className="me-1">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.08584 15.6094C7.04025 15.609 6.00965 15.3607 5.07872 14.8846C4.14778 14.4086 3.34307 13.7185 2.73069 12.871C2.11832 12.0235 1.71575 11.0428 1.55606 10.0094C1.39636 8.97611 1.48411 7.91964 1.81208 6.92682C2.14006 5.934 2.6989 5.03316 3.4427 4.2983C4.1865 3.56344 5.09403 3.01553 6.09075 2.69958C7.08746 2.38364 8.14491 2.30867 9.17623 2.48085C10.2076 2.65302 11.1833 3.06742 12.0233 3.69C12.1358 3.78136 12.2086 3.91257 12.2267 4.05629C12.2448 4.20002 12.2067 4.34519 12.1205 4.46156C12.0342 4.57793 11.9063 4.65652 11.7635 4.68097C11.6208 4.70542 11.474 4.67384 11.354 4.59281C10.3625 3.85669 9.15164 3.47654 7.91731 3.51383C6.68299 3.55112 5.49733 4.00368 4.55207 4.79832C3.60682 5.59296 2.95725 6.68322 2.7084 7.89277C2.45956 9.10233 2.62599 10.3605 3.18078 11.4637C3.73557 12.567 4.64629 13.4508 5.76565 13.9723C6.88501 14.4939 8.14756 14.6226 9.34913 14.3376C10.5507 14.0527 11.621 13.3708 12.387 12.4022C13.153 11.4336 13.5699 10.2349 13.5702 9C13.5694 8.71079 13.5459 8.42209 13.4999 8.13656C13.4885 8.06269 13.4917 7.98729 13.5094 7.91467C13.527 7.84204 13.5589 7.77361 13.603 7.71328C13.6471 7.65295 13.7027 7.6019 13.7666 7.56306C13.8305 7.52421 13.9013 7.49833 13.9752 7.48688C14.0491 7.47543 14.1245 7.47864 14.1971 7.49633C14.2697 7.51402 14.3382 7.54584 14.3985 7.58998C14.4588 7.63411 14.5099 7.6897 14.5487 7.75357C14.5876 7.81743 14.6135 7.88832 14.6249 7.96219C14.6752 8.30578 14.6987 8.65276 14.6952 9C14.6937 10.7525 13.9969 12.4327 12.7577 13.6719C11.5186 14.9111 9.83829 15.6079 8.08584 15.6094Z"
                            fill="white"
                          />
                          <path
                            d="M8.78963 11.108C8.64069 11.1074 8.49807 11.0477 8.39307 10.9421L5.72119 8.27018C5.62904 8.16257 5.58089 8.02415 5.58636 7.88259C5.59183 7.74102 5.65051 7.60673 5.75069 7.50655C5.85087 7.40637 5.98516 7.34768 6.12673 7.34222C6.26829 7.33675 6.40671 7.3849 6.51432 7.47705L8.78963 9.74955L15.5649 2.97705C15.6726 2.8849 15.811 2.83675 15.9525 2.84222C16.0941 2.84768 16.2284 2.90637 16.3286 3.00655C16.4288 3.10673 16.4874 3.24102 16.4929 3.38259C16.4984 3.52415 16.4502 3.66257 16.3581 3.77018L9.18619 10.9421C9.08119 11.0477 8.93858 11.1074 8.78963 11.108Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    }
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export function FabricationReadOnlyComponent(props) {
  const dispatch = useDispatch();
  const {
    beneficiary_id,
    status,
    stageIds,
    fabrication_data,
    fabricationDisabled,
  } = props;

  const [complete, setComplete] = useState(false);

  const initialValues = {
    [FabricationFields.COMPLETIONNOTES]: "",
  };
  const [initialObject, setInitialObject] = useState(initialValues);

  const validationSchema = Yup.object().shape({
    [FabricationFields.COMPLETIONNOTES]: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (fabrication_data) {
      if (fabrication_data?.data?.length === 0) {
        setInitialObject(fabrication_data?.data?.[0]);
        console.log(111111111111);
      } else if (fabrication_data?.data?.length > 0) {
        setInitialObject(fabrication_data?.data?.[0]);
      }
    }
  }, [fabrication_data]);

  return (
    <>
      <div className={``}>
        <Formik
          initialValues={initialObject}
          // validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            // if (values?.buttonName === "Start") {
            //   const updatedValues = {
            //     beneficiary_id,
            //     beneficiary_status: {
            //       beneficiary_stage_id: stageIds?.fabrication,
            //       status_id: values.status_id,
            //       data: [],
            //     },
            //   };
            //   // console.log({ values, updatedValues });
            //   dispatch(updateBeneficiaryJourneyByBeneficiaryId(updatedValues));
            // } else if (values?.buttonName === "Completed") {
            //   const updatedValues = {
            //     beneficiary_id,
            //     beneficiary_status: {
            //       beneficiary_stage_id: stageIds?.fabrication,
            //       status_id: values.status_id,
            //       data: [
            //         {
            //           [FabricationFields.COMPLETIONNOTES]:
            //             values[FabricationFields.COMPLETIONNOTES],
            //         },
            //       ],
            //     },
            //   };
            //   dispatch(updateBeneficiaryJourneyByBeneficiaryId(updatedValues));
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            >
              <div className="flex flex-col space-y-4 mb-4">
                <div className="w-full">
                  {fabricationDisabled ? (
                    <></>
                  ) : (
                    <CustomButton
                      name="Start"
                      type="submit"
                      loading={false}
                      disabled={fabricationDisabled}
                      onClick={(e) => {
                        // e.preventDefault();
                        setFieldValue("buttonName", "Start");
                        setFieldValue(
                          "status_id",
                          status?.fabrication_in_progress
                        );
                      }}
                      className={`text-white  flex flex-row items-center justify-center space-x-2 ${
                        complete ? "bg-[#C1C1C1]" : "bg-WildWatermelon-button"
                      }`}
                      icon={
                        <span className="me-1">
                          {" "}
                          <svg
                            width="12"
                            height="15"
                            viewBox="0 0 12 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 2.75V0.5L3 3.5L6 6.5V4.25C8.4825 4.25 10.5 6.2675 10.5 8.75C10.5 10.9775 8.8725 12.8225 6.75 13.1825V14.6975C9.7125 14.33 12 11.81 12 8.75C12 5.435 9.315 2.75 6 2.75ZM1.5 8.75C1.5 7.5125 2.0025 6.3875 2.82 5.57L1.755 4.505C1.198 5.06263 0.756312 5.72453 0.455183 6.4529C0.154055 7.18127 -0.000617915 7.96184 1.85519e-06 8.75C1.85519e-06 11.81 2.2875 14.33 5.25 14.6975V13.1825C3.1275 12.8225 1.5 10.9775 1.5 8.75Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      }
                    />
                  )}
                </div>
                <div className="w-full">
                  <label className="text-sm font-bold">Completion Notes</label>
                  <TextArea
                    name={FabricationFields.COMPLETIONNOTES}
                    id={FabricationFields.COMPLETIONNOTES}
                    value={values[FabricationFields.COMPLETIONNOTES]}
                    placeHolder={""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={"text"}
                    className="h-[79px] py-1"
                    disabled={fabricationDisabled}
                  />
                  {touched[FabricationFields.COMPLETIONNOTES] &&
                    errors[FabricationFields.COMPLETIONNOTES] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[FabricationFields.COMPLETIONNOTES]}
                      </div>
                    )}
                </div>
                <div className="w-full">
                  {fabricationDisabled ? (
                    <></>
                  ) : (
                    <CustomButton
                      name="Completed"
                      type="submit"
                      loading={false}
                      disabled={fabricationDisabled}
                      onClick={(e) => {
                        // e.preventDefault();
                        setFieldValue("buttonName", "Completed");
                        setFieldValue(
                          "status_id",
                          status?.fabrication_completed
                        );
                      }}
                      className={`text-white px-4 py-1 rounded-md font-bold  h-full flex flex-row items-center justify-center space-x-2 ${
                        complete ? "bg-WildWatermelon-button" : "bg-[#C1C1C1]"
                      }`}
                      icon={
                        <span className="me-1">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.08584 15.6094C7.04025 15.609 6.00965 15.3607 5.07872 14.8846C4.14778 14.4086 3.34307 13.7185 2.73069 12.871C2.11832 12.0235 1.71575 11.0428 1.55606 10.0094C1.39636 8.97611 1.48411 7.91964 1.81208 6.92682C2.14006 5.934 2.6989 5.03316 3.4427 4.2983C4.1865 3.56344 5.09403 3.01553 6.09075 2.69958C7.08746 2.38364 8.14491 2.30867 9.17623 2.48085C10.2076 2.65302 11.1833 3.06742 12.0233 3.69C12.1358 3.78136 12.2086 3.91257 12.2267 4.05629C12.2448 4.20002 12.2067 4.34519 12.1205 4.46156C12.0342 4.57793 11.9063 4.65652 11.7635 4.68097C11.6208 4.70542 11.474 4.67384 11.354 4.59281C10.3625 3.85669 9.15164 3.47654 7.91731 3.51383C6.68299 3.55112 5.49733 4.00368 4.55207 4.79832C3.60682 5.59296 2.95725 6.68322 2.7084 7.89277C2.45956 9.10233 2.62599 10.3605 3.18078 11.4637C3.73557 12.567 4.64629 13.4508 5.76565 13.9723C6.88501 14.4939 8.14756 14.6226 9.34913 14.3376C10.5507 14.0527 11.621 13.3708 12.387 12.4022C13.153 11.4336 13.5699 10.2349 13.5702 9C13.5694 8.71079 13.5459 8.42209 13.4999 8.13656C13.4885 8.06269 13.4917 7.98729 13.5094 7.91467C13.527 7.84204 13.5589 7.77361 13.603 7.71328C13.6471 7.65295 13.7027 7.6019 13.7666 7.56306C13.8305 7.52421 13.9013 7.49833 13.9752 7.48688C14.0491 7.47543 14.1245 7.47864 14.1971 7.49633C14.2697 7.51402 14.3382 7.54584 14.3985 7.58998C14.4588 7.63411 14.5099 7.6897 14.5487 7.75357C14.5876 7.81743 14.6135 7.88832 14.6249 7.96219C14.6752 8.30578 14.6987 8.65276 14.6952 9C14.6937 10.7525 13.9969 12.4327 12.7577 13.6719C11.5186 14.9111 9.83829 15.6079 8.08584 15.6094Z"
                              fill="white"
                            />
                            <path
                              d="M8.78963 11.108C8.64069 11.1074 8.49807 11.0477 8.39307 10.9421L5.72119 8.27018C5.62904 8.16257 5.58089 8.02415 5.58636 7.88259C5.59183 7.74102 5.65051 7.60673 5.75069 7.50655C5.85087 7.40637 5.98516 7.34768 6.12673 7.34222C6.26829 7.33675 6.40671 7.3849 6.51432 7.47705L8.78963 9.74955L15.5649 2.97705C15.6726 2.8849 15.811 2.83675 15.9525 2.84222C16.0941 2.84768 16.2284 2.90637 16.3286 3.00655C16.4288 3.10673 16.4874 3.24102 16.4929 3.38259C16.4984 3.52415 16.4502 3.66257 16.3581 3.77018L9.18619 10.9421C9.08119 11.0477 8.93858 11.1074 8.78963 11.108Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
