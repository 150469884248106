/**
 * Camp OverView Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Sidebar } from "../../../components";
import CurrentCampCardComponent from "../components/currentCampCard";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { RefreshIcon } from "../../../assets/icons/refresh";
import HeadingComponent from "../components/headingComponent";
import BeneficiaryShortDetailCardComponent from "../components/beneficiaryShortDetailCard";
import { NoRecord } from "../../../components/noRecords";
import { allCurrentCamps, setViewSingleBeneficiaryDetail } from "../slice";
import {
  SelectActiveCampCounter,
  SelectActiveCampStep,
  SelectActiveCurrentCamp,
  SelectAllBeneficiaryListOfCamp,
  SelectAllBeneficiaryListOfCounterOfStep,
  SelectAllBeneficiaryListOfStepOfCamp,
  SelectAllCurrentCampsList,
  SelectViewSingleBeneficiaryDetail,
} from "../selector";
import { BeneficiaryDetailAndJourneyDataModels } from "../../../components/beneficiayDetailAndJourneyData";

const CampOverviewScreen = () => {
  const dispatch = useDispatch();
  const [recordData, setRecordData] = useState(true);
  const [isCampFlow, setIsCampFlow] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState("");

  const viewBeneficiaryModal = useSelector(SelectViewSingleBeneficiaryDetail);
  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);
  const activeCampStep = useSelector(SelectActiveCampStep);
  const activeCampCounter = useSelector(SelectActiveCampCounter);
  const campOverviewList = useSelector(SelectAllCurrentCampsList);
  const campBeneficiarDetails = useSelector(SelectAllBeneficiaryListOfCamp);
  const beneficiarDetailsOfStep = useSelector(
    SelectAllBeneficiaryListOfStepOfCamp
  );
  const beneficiarDetailsOfCounter = useSelector(
    SelectAllBeneficiaryListOfCounterOfStep
  );

  console.log(beneficiarDetailsOfCounter);

  const toggleBeneficaryDetailModal = () => {
    dispatch(setViewSingleBeneficiaryDetail(!viewBeneficiaryModal));
  };

  const getAllData = () => {
    dispatch(
      allCurrentCamps({
        search: "",
        // page: 1,
        // responsePerPage: 10,
      })
    );
  };

  // Helper function to render content based on active status
  const renderContent = () => {
    if (activeCurrentCamp.isActiveCurrentCamp) {
      return (
        <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Not yet checked-in"}
              total={campBeneficiarDetails?.counts?.notCheckedIn?.count || 0}
              colors={{ text: "#FF5976", background: "#FFEBEE" }}
            />
            {/* options */}
            {campBeneficiarDetails?.counts?.notCheckedIn?.beneficiaries?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div
                    key={beneficiaryIndex}
                    onClick={() => {
                      setBeneficiaryId(beneficiary?._id);
                      toggleBeneficaryDetailModal();
                    }}
                  >
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={false}
                      time={false}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"In Progress"}
              total={campBeneficiarDetails?.counts?.inProgress?.count || 0}
              colors={{ text: "#FAAE00", background: "#FEF5E0" }}
            />
            {/* options */}
            {campBeneficiarDetails?.counts?.inProgress?.beneficiaries?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div
                    key={beneficiaryIndex}
                    onClick={() => {
                      setBeneficiaryId(beneficiary?._id);
                      toggleBeneficaryDetailModal();
                    }}
                  >
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={true}
                      counterNumber={true}
                      time={true}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Checked-out"}
              total={campBeneficiarDetails?.counts?.checkedOut?.count || 0}
              colors={{ text: "#04CF3D", background: "#E0F9E7" }}
            />
            {/* options */}
            {campBeneficiarDetails?.counts?.checkedOut?.beneficiaries?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div
                    key={beneficiaryIndex}
                    onClick={() => {
                      setBeneficiaryId(beneficiary?._id);
                      toggleBeneficaryDetailModal();
                    }}
                  >
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={false}
                      time={true}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    } else if (activeCampStep.isActiveCampStep) {
      return (
        <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Completed"}
              total={beneficiarDetailsOfStep?.completed}
              colors={{ text: "#0078CE", background: "#DDF1FE" }}
            />
            {/* options */}
            {beneficiarDetailsOfStep?.completedBeneficiary?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div key={beneficiaryIndex} onClick={() => {}}>
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={true}
                      time={true}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Waiting"}
              total={beneficiarDetailsOfStep?.waiting}
              colors={{ text: "#04CF3D", background: "#E0F9E7" }}
            />
            {/* options */}
            {beneficiarDetailsOfStep?.waitingBeneficiary?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div key={beneficiaryIndex} onClick={() => {}}>
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={true}
                      time={false}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    } else if (activeCampCounter.isActiveCampCounter) {
      return (
        <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Completed"}
              total={beneficiarDetailsOfCounter?.completed}
              colors={{ text: "#0078CE", background: "#DDF1FE" }}
            />
            {/* options */}
            {beneficiarDetailsOfCounter?.completedBeneficiary?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div key={beneficiaryIndex} onClick={() => {}}>
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={false}
                      time={true}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="space-y-3 w-full">
            {/* heading */}
            <HeadingComponent
              name={"Waiting"}
              total={beneficiarDetailsOfCounter?.waiting}
              colors={{ text: "#04CF3D", background: "#E0F9E7" }}
            />
            {/* options */}
            {beneficiarDetailsOfCounter?.waitingBeneficiary?.map(
              (beneficiary, beneficiaryIndex) => {
                return (
                  <div key={beneficiaryIndex} onClick={() => {}}>
                    <BeneficiaryShortDetailCardComponent
                      beneficiaryData={beneficiary}
                      campDetails={campBeneficiarDetails?.camp}
                      viewCampName={false}
                      counterNumber={false}
                      time={false}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex h-[calc(100%-100px)] items-center justify-around px-4 space-x-2">
          <NoRecord
            title_1="Select a Camp to view"
            title_2="Click on the Beneficiaries to view details"
          />
        </div>
      );
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          <div className="flex flex-col h-full bg-customBlue-mainBackground w-[100%] overflow-auto">
            <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
              <div className="font-bold font-raleway text-2xl">
                Ongoing Camps
              </div>
            </div>
            {/* Main Contants */}

            <div class="flex flex-row justify-between space-x-2 px-4 h-screen">
              <div className="max-h-[calc(100vh-160px)] overflow-auto px-2 pb-3 min-w-[330px] ">
                <div class=" flex flex-col gap-4 ">
                  {campOverviewList?.map((campData, campIndex) => (
                    <div
                      className={`text-sm shadow-md p-4 rounded-xl cursor-pointer bg-white`}
                    >
                      <div
                        key={campIndex}
                        // onClick={() => {
                        //   handleCampClick(campData, campIndex);
                        // }}
                      >
                        <CurrentCampCardComponent
                          campData={campData}
                          campIndex={campIndex}
                          setIsCampFlow={setIsCampFlow}
                          isCampFlow={isCampFlow}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="p-3 pt-0 w-[calc(100%-330px)] space-y-4">
                <div className="breadcrumb font-bold">{`${activeCurrentCamp?.campName}${activeCurrentCamp?.navStatus}${activeCampStep?.campStepName}${activeCampCounter?.campCounterName}`}</div>

                <div className="flex gap-3">
                  <div className="relative h-[40px] rounded-xl w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-5 pointer-events-none h-full ">
                      <SearchIcon width={24} height={24} />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full h-full p-4 ps-12 text-base text-gray-tone-100 rounded-xl search-bar"
                      placeholder="Search By Mobile Number/Name"
                    />
                  </div>
                  <button
                    className="inline-flex flex-row px-4 justify-around rounded-lg items-center gap-2 bg-[#4AB8FF] text-white font-semibold"
                    // onClick={toggleAddModal}
                  >
                    <span className="flex-shrink-0">
                      <RefreshIcon width={24} height={24} />
                    </span>
                    <span className="font-semibold truncate">Refresh</span>
                  </button>
                </div>
                {/* Records */}
                {recordData ? (
                  renderContent()
                ) : (
                  <div className="flex h-[calc(100%-100px)] items-center justify-around px-4 space-x-2">
                    <NoRecord
                      title_1={"Select a Camp to view"}
                      title_2={"Click on the Beneficiaries to view details"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewBeneficiaryModal && (
        <BeneficiaryDetailAndJourneyDataModels
          toggleModal={toggleBeneficaryDetailModal}
          beneficiaryId={beneficiaryId}
        />
      )}
    </>
  );
};

export { CampOverviewScreen };
