/**
 * Schedule Camp Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allScheduleCampsDataList: [],
  scheduleCamp: {},
  currentPage: 1,
  respPerPage: 10,
  filter: "",
  searchQuery: "",
  campCenterOptionsByState: [],
  stateWithScheduleCampCount: [],
  stateIdForScheduleCamp: "",
  //Schedule Camp Step Sequence
  allScheduledCampStepsDataList: [],
  allAssinedBeneficiary: {},
  allCampListForSelect: {},
  allBeneficiariesListForCamp: {},
  beneficiariesQueryList: {},
  // my camps
  allMyCamps: {},

  beneficiaryQueryCurrentPage: 1,
  beneficiaryQueryRespPerPage: 10,
  beneficiaryQuerySearch: "",

  campUserCounterData: {},
};

const scheduleCampManagementSlice = createSlice({
  name: "scheduleCampManagement",
  initialState,
  reducers: {
    setAllScheduleCampList(state, action) {
      state.allScheduleCampsDataList = action.payload;
    },
    setScheduleCamp(state, action) {
      state.scheduleCamp = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setRespPerPage(state, action) {
      state.respPerPage = action.payload;
    },
    setStateIdForScheduleCamp(state, action) {
      state.stateIdForScheduleCamp = action.payload;
    },
    setScheduleCampFilter(state, action) {
      state.filter = action.payload;
    },
    setScheduleCampSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setScheduleCampStepsDataList(state, action) {
      state.allScheduledCampStepsDataList = action.payload;
    },
    setAllAssinBeneficiayDataList(state, action) {
      state.allAssinedBeneficiary = action.payload;
    },
    setAllCampListForSelectBox(state, action) {
      state.allCampListForSelect = action.payload;
    },
    setAllBeneficiariesListForCamp(state, action) {
      state.allBeneficiariesListForCamp = action.payload;
    },
    setBeneficiariesQueryList(state, action) {
      state.beneficiariesQueryList = action.payload;
    },
    setAllMyCamps(state, action) {
      state.allMyCamps = action.payload;
    },
    setbeneficiaryQueryCurrentPage(state, action) {
      state.beneficiaryQueryCurrentPage = action.payload;
    },
    setbeneficiaryQueryRespPerPage(state, action) {
      state.beneficiaryQueryRespPerPage = action.payload;
    },
    setbeneficiaryQuerySearch(state, action) {
      state.beneficiaryQuerySearch = action.payload;
    },
    setCampUserCounterData(state, action) {
      state.campUserCounterData = action.payload;
    },
    setCampCenterOptionsByState(state, action) {
      state.campCenterOptionsByState = action.payload;
    },
    setAllStateWithScheduleCampCount(state, action) {
      state.stateWithScheduleCampCount = action.payload;
    },
  },
});

// Reducer
export const scheduleCampManagementReducer =
  scheduleCampManagementSlice.reducer;

// Actions
export const {
  setAllScheduleCampList,
  setScheduleCamp,
  setCurrentPage,
  setRespPerPage,
  setScheduleCampFilter,
  setScheduleCampSearchQuery,
  setScheduleCampStepsDataList,
  setAllAssinBeneficiayDataList,
  setAllCampListForSelectBox,
  setAllBeneficiariesListForCamp,
  setBeneficiariesQueryList,
  setAllMyCamps,
  setbeneficiaryQuerySearch,
  setbeneficiaryQueryRespPerPage,
  setbeneficiaryQueryCurrentPage,
  setCampUserCounterData,
  setCampCenterOptionsByState,
  setStateIdForScheduleCamp,
  setAllStateWithScheduleCampCount,
} = scheduleCampManagementSlice.actions;

export const allScheduleCampList = createAction("ALLSCHEDULECAMPLIST");
export const createScheduleCamp = createAction("CREATENEWSCHEDULECAMP");
export const updateScheduleCampById = createAction("UPDATESCHEDULECAMPBYID");
export const getScheduleCampById = createAction("GETSCHEDULECAMPBYID");
export const deleteScheduleCamp = createAction("DELETESCHEDULECAMP");
export const getCampCenterByStateId = createAction("GETCAMPCENTERBYSTATEID");
export const getallStateWithScheduleCampCount = createAction(
  "GETALLSTATEWITHSCHEDULECAMPCOUNT"
);
//Schedule Camp Step
export const getScheduleCampStepById = createAction("GETSCHEDULECAMPSTEPBYID");
export const updateRequiredDataOfCampSteps = createAction(
  "UPDATEREQUIREDATAOFCAMPSTEPS"
);

//Schedule Camp Step Sequence
export const createScheduledCampStepSequence = createAction(
  "CREATESCHEDULECAMPSTEPSEQUENCE"
);
export const updateScheduledCampStepSequence = createAction(
  "UPDATESCHEDULECAMPSTEPSEQUENCE"
);
export const getAllScheduledCampStepSequence = createAction(
  "GETALLSCHEDULECAMPSTEPSEQUENCE"
);
export const getScheduleCampStepByScheduleCampId = createAction(
  "GETSCHEDULECAMPSTEPSBYSCHEDULEDCAMPID"
);
//Schedule Camp Counters
export const getScheduleCampCountersByScheduleCampId = createAction(
  "GETSCHEDULECAMPCOUNTERSBYSCHEDULEDCAMPID"
);
export const assignUsertoCampCounters = createAction(
  "ASSIGNUSERTOCAMPCOUNTERS"
);
export const getBeneficiariesQueryDataOfCamp = createAction(
  "GETBENEFICIARIESQUERYDATAOFCAMP"
);
export const assignBeneficiariestoCamp = createAction(
  "ASSIGNBENEFICIARIESTOCAMP"
);
export const getAllAssignBeneficiariesOfCamp = createAction(
  "GETALLASSIGNBENEFICIARIESOFCAMP"
);
export const getAllCampsForSelectBox = createAction("GETALLCAMPSFORSELECTBOX");
export const getAllBeneficiariesOfCampByScheduleCampId = createAction(
  "GETALLBENEFICIARYOFCAMPSBYSCHEDULECAMPID"
);
export const getAllBeneficiariesOfCampByScheduleCampIdForCheckIn = createAction(
  "GETALLBENEFICIARYOFCAMPSBYSCHEDULECAMPIDFORCHECKIN"
);
export const deleteCampCounterByCounterId = createAction(
  "DELETECAMPCOUNTERBYCOUNTERID"
);

// Temp Query
export const getAllBeneficiaryQueryDataByScheduleCampId = createAction(
  "GETALLBENEFICIARYQUERYDATABYSCHEDULECAMPID"
);
export const updateBeneficiaryQueryDataById = createAction(
  "UPODATEBENEFICIARYQUERYDATABYID"
);
export const getBeneficiaryQueryDataById = createAction(
  "GETBENEFICIARYQUERYDATABYID"
);
export const deleteBeneficiaryQueryById = createAction(
  "DELETEBENEFICIARYQUERYBYID"
);
export const deleteBeneficiaryFromQuery = createAction(
  "DELETEBENEFICIARYFROMQUERY"
);

// my camp
export const getAllMyCampsList = createAction("GETALLMYCAMPS");
export const getStepAndCounterOfUser = createAction("GETUSERSTEPANDCOUNT");
export const updateToggleForAvailableUnavailable = createAction(
  "UPDATETOGGLEAVAILABLEUNAVAILABLE"
);
