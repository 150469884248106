/**
 * Fabrication Management Saga
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  allBeneficiaryListOfFabricationCamp,
  allFabricationCampsList,
  setAllFabricationCampsBeneficiaryDataList,
  setAllFabricationCampsDataList,
} from "./slice";
import { endPoints, httpRequest } from "../../services";

/**
 * Get all Role list
 * @param {*}
 */
function* allCompletedAssessmentCampsListSaga(action) {
  try {
    const { search, filter, page, perPage, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allFabricationCamp, {
      search,
      filter,
      //   page,
      //   responsePerPage: perPage,
    });
    yield put(setAllFabricationCampsDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 *  get All Beneficiaries Of Camp By Schedule Camp Id
 * @param {*}
 */
function* getAllBeneficiaryListOfFabricationCampSaga(action) {
  try {
    const {
      scheduled_camp_id,
      search,
      stateId,
      districtId,
      status,
      page,
      filter,
      perPage,
      onSuccess,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiayFabricationCamp,
      {
        scheduled_camp_id,
        search,
        state: stateId,
        district: districtId,
        // pinCode: filter.pinCode,
        page,
        responsePerPage: perPage,
        status,
      }
    );
    yield put(setAllFabricationCampsBeneficiaryDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* fabricationManagementSaga() {
  yield takeLatest(
    allFabricationCampsList,
    allCompletedAssessmentCampsListSaga
  );
  yield takeLatest(
    allBeneficiaryListOfFabricationCamp,
    getAllBeneficiaryListOfFabricationCampSaga
  );
}
