/**
 * Audio Player Component
 * @format
 */

import WavesurferPlayer from "@wavesurfer/react";
import { useState, useEffect } from "react";
import { Play } from "../../../assets/icons/Play";
import { Pause } from "../../../assets/icons/Pause";

const AudioPlayer = ({ voice_record }) => {
  // Initialize players with unique ids and required properties
  const [players, setPlayers] = useState(
    voice_record.map((url, index) => ({
      id: index,
      url,
      isPlaying: false,
      currentTime: 0,
      wavesurfer: null,
    }))
  );

  // Function to initialize the Wavesurfer instance for each player
  const onReady = (id, ws) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) =>
        player.id === id ? { ...player, wavesurfer: ws } : player
      )
    );
  };

  // Toggle play/pause
  const onPlayPause = (id) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) => {
        if (player.id === id && player.wavesurfer) {
          const isPlaying = !player.isPlaying;
          if (isPlaying) {
            player.wavesurfer.play();
          } else {
            player.wavesurfer.pause();
          }
          return { ...player, isPlaying };
        }
        return player;
      })
    );
  };

  // Update current time for each player
  useEffect(() => {
    const timer = setInterval(() => {
      setPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.isPlaying && player.wavesurfer
            ? { ...player, currentTime: player.wavesurfer.getCurrentTime() }
            : player
        )
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [players]);

  // Format time in MM:SS format
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="flex gap-8">
      {players.map((player) => (
        <div
          key={player.id}
          className="p-2 flex flex-row justify-between px-3 items-center max-w-[266px] w-full gap-4 border border-[#EDEDED] rounded-md"
        >
          <div className="flex justify-center items-center">
            <button
              onClick={() => onPlayPause(player.id)}
              className="flex justify-center items-center bg-blue-500 rounded-full min-h-[36px] min-w-[36px]"
            >
              {player.isPlaying ? (
                <Pause width={18} height={18} />
              ) : (
                <Play width={18} height={18} />
              )}
            </button>
          </div>
          <div className="flex-auto">
            <WavesurferPlayer
              barWidth={2}
              barRadius={2}
              progressColor="gray-500"
              barGap={4}
              height={20}
              waveColor="grey"
              url={player.url}
              onReady={(ws) => onReady(player.id, ws)}
              onPlay={() =>
                setPlayers((prevPlayers) =>
                  prevPlayers.map((p) =>
                    p.id === player.id ? { ...p, isPlaying: true } : p
                  )
                )
              }
              onPause={() =>
                setPlayers((prevPlayers) =>
                  prevPlayers.map((p) =>
                    p.id === player.id ? { ...p, isPlaying: false } : p
                  )
                )
              }
            />
          </div>
          <div className="flex justify-center items-center w-[15%] font-DM font-[14px]">
            {formatTime(player.currentTime)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AudioPlayer;
