/**
 * Add New Camp Center Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { CampCenterFields } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Cross } from "../../../assets/icons/Cross";
import { Formik } from "formik";
import SelectDropDown from "../../../components/selectDropDown";
import TextAreaWithEditorComponent from "../../../components/textAreaWithEditor";
import { Switch } from "@material-tailwind/react";
import {
  getAllDistrict,
  getAllState,
  getDistrictByStateId,
  selectDistrict,
  selectDistrictByState,
  selectState,
  setDistrictByState,
} from "../../common";
import { createCampCenter } from "../slice";
import { toast } from "react-toastify";
import CustomButton from "../../../components/customButtion";
import {
  selectCampCenterSearchQuery,
  selectCurrentPage,
  selectRespPerPage,
  selectStateId,
} from "../selector";

const AddCampCenterModel = ({
  toggleModal,
  getData,
  getStateWithCenterCount,
}) => {
  const dispatch = useDispatch();
  const [campCenterStatus, setCampCenterStatus] = useState(true);

  const currentPage = useSelector(selectCurrentPage);
  const perPage = useSelector(selectRespPerPage);
  const stateId = useSelector(selectStateId) || "";
  const campCenterSearchQuery = useSelector(selectCampCenterSearchQuery) || "";

  const initialValues = {
    [CampCenterFields.CAMP_CENTER_NAME]: "",
    [CampCenterFields.ADDRESS_1]: "",
    [CampCenterFields.ADDRESS_2]: "",
    [CampCenterFields.STATE]: "",
    [CampCenterFields.DISTRICT]: "",
    [CampCenterFields.PIN_CODE]: "",
    [CampCenterFields.GEO_COORDINATES]: "",
    [CampCenterFields.CONTACT_PERSON_NAME]: "",
    [CampCenterFields.CONTACT_PERSON_PHONE]: "",
    [CampCenterFields.NOTES]: "",
    [CampCenterFields.IS_ACTIVE]: campCenterStatus,
  };

  const validationSchema = Yup.object().shape({
    [CampCenterFields.CAMP_CENTER_NAME]: Yup.string()
      .required("Camp Center Name is required")
      .min(3, "Camp Center Name must be at least 3 characters"),
    [CampCenterFields.ADDRESS_1]: Yup.string().required(
      "Address 1 is required"
    ),
    [CampCenterFields.ADDRESS_2]: Yup.string().optional(),
    [CampCenterFields.STATE]: Yup.string().required("State is required"),
    [CampCenterFields.DISTRICT]: Yup.string().required("District is required"),
    [CampCenterFields.PIN_CODE]: Yup.string()
      .required("Pincode is required")
      .matches(/^[0-9]{6}$/, "Pincode must be 6 digits"),
    [CampCenterFields.GEO_COORDINATES]: Yup.string().optional(),
    [CampCenterFields.CONTACT_PERSON_NAME]: Yup.string()
      .required("Contact Person Name is required")
      .min(3, "Name must be at least 3 characters"),
    [CampCenterFields.CONTACT_PERSON_PHONE]: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    [CampCenterFields.NOTES]: Yup.string().optional(),
    [CampCenterFields.IS_ACTIVE]: Yup.string().required("Status is required"),
  });

  // Apis Data
  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptions = state?.map((item) => ({
    label: item?.state_name,
    value: item?._id,
  }));

  const { data: districtByState = [] } =
    useSelector(selectDistrictByState) || {};
  const districtByStateOptions = districtByState?.map((item) => ({
    label: item?.district_name,
    value: item?._id,
  }));

  //   Functions
  const getState = () => {
    dispatch(
      getAllState({
        // state_name: "",
        // iso_code: "",
        onSuccess: (props) => {
          console.log("All State loaded successfully");
        },
      })
    );
  };

  const getDistrict = () => {
    dispatch(
      getAllDistrict({
        onSuccess: (props) => {
          console.log("All District loaded successfully");
        },
      })
    );
  };

  const getDistrictListByState = (id) => {
    dispatch(
      getDistrictByStateId({
        stateId: id,
      })
    );
  };

  useEffect(() => {
    getState();
    getDistrict();
    dispatch(setDistrictByState([]));
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          dispatch(
            createCampCenter({
              values: values,
              onSuccess: (props) => {
                toast.success(props?.message);
                toggleModal();
                getData(campCenterSearchQuery, stateId, currentPage, perPage);
                getStateWithCenterCount();
              },
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="fixed h-full inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div
                data-aos-easing="linear"
                data-aos="fade-left"
                className="flex flex-col relative w-[50%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
              >
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-xl font-bold">Create Camp Centers</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="flex flex-auto flex-col h-[calc(100vh-80px)] justify-between px-4 py-4 w-full">
                  <div
                    className={
                      "w-full flex flex-col gap-3 mb-4 overflow-auto px-4 h-full"
                    }
                  >
                    <div className="w-full">
                      <label className="text-sm font-bold">
                        Camp Center Name
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.CAMP_CENTER_NAME}
                        id={CampCenterFields.CAMP_CENTER_NAME}
                        onChange={handleChange}
                        value={values[CampCenterFields.CAMP_CENTER_NAME]}
                        onBlur={handleBlur}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.CAMP_CENTER_NAME] &&
                        errors[CampCenterFields.CAMP_CENTER_NAME] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.CAMP_CENTER_NAME]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">Address 1</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.ADDRESS_1}
                        id={CampCenterFields.ADDRESS_1}
                        onChange={handleChange}
                        value={values[CampCenterFields.ADDRESS_1]}
                        onBlur={handleBlur}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.ADDRESS_1] &&
                        errors[CampCenterFields.ADDRESS_1] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.ADDRESS_1]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">Address 2</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.ADDRESS_2}
                        id={CampCenterFields.ADDRESS_2}
                        onChange={handleChange}
                        value={values[CampCenterFields.ADDRESS_2]}
                        onBlur={handleBlur}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.ADDRESS_2] &&
                        errors[CampCenterFields.ADDRESS_2] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.ADDRESS_2]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">State</label>
                      <SelectDropDown
                        name={CampCenterFields.STATE}
                        options={stateOptions}
                        value={
                          stateOptions?.find(
                            (item) =>
                              item?.value === values[CampCenterFields.STATE]
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(CampCenterFields.STATE, selectedAccess);
                          getDistrictListByState(selectedOption?.value);
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={false}
                      />
                      {touched[CampCenterFields.STATE] &&
                        errors[CampCenterFields.STATE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.STATE]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">District</label>
                      <SelectDropDown
                        name={CampCenterFields.DISTRICT}
                        options={districtByStateOptions}
                        value={
                          districtByStateOptions?.find(
                            (item) =>
                              item?.value === values[CampCenterFields.DISTRICT]
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(
                            CampCenterFields.DISTRICT,
                            selectedAccess
                          );
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={false}
                      />
                      {touched[CampCenterFields.DISTRICT] &&
                        errors[CampCenterFields.DISTRICT] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.DISTRICT]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">Pincode</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.PIN_CODE}
                        id={CampCenterFields.PIN_CODE}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          handleChange({
                            target: {
                              name: CampCenterFields.PIN_CODE,
                              value: numericValue,
                            },
                          });
                        }}
                        value={values[CampCenterFields.PIN_CODE]}
                        onBlur={handleBlur}
                        maxLength={6}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.PIN_CODE] &&
                        errors[CampCenterFields.PIN_CODE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.PIN_CODE]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">
                        Geo Coordinates{" "}
                        <span className="text-xs font-medium text-gray-700">
                          (Copy and paste from Google Map)
                        </span>
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.GEO_COORDINATES}
                        id={CampCenterFields.GEO_COORDINATES}
                        onChange={handleChange}
                        value={values[CampCenterFields.GEO_COORDINATES]}
                        onBlur={handleBlur}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.GEO_COORDINATES] &&
                        errors[CampCenterFields.GEO_COORDINATES] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.GEO_COORDINATES]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">
                        Contact Person Name
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.CONTACT_PERSON_NAME}
                        id={CampCenterFields.CONTACT_PERSON_NAME}
                        onChange={handleChange}
                        value={values[CampCenterFields.CONTACT_PERSON_NAME]}
                        onBlur={handleBlur}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.CONTACT_PERSON_NAME] &&
                        errors[CampCenterFields.CONTACT_PERSON_NAME] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.CONTACT_PERSON_NAME]}
                          </div>
                        )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-bold">
                        Contact Person Phone
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampCenterFields.CONTACT_PERSON_PHONE}
                        id={CampCenterFields.CONTACT_PERSON_PHONE}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          handleChange({
                            target: {
                              name: CampCenterFields.CONTACT_PERSON_PHONE,
                              value: numericValue,
                            },
                          });
                        }}
                        value={values[CampCenterFields.CONTACT_PERSON_PHONE]}
                        onBlur={handleBlur}
                        maxLength={10}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampCenterFields.CONTACT_PERSON_PHONE] &&
                        errors[CampCenterFields.CONTACT_PERSON_PHONE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.CONTACT_PERSON_PHONE]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Notes</label>

                      <TextAreaWithEditorComponent
                        name={CampCenterFields.NOTES}
                        values={values}
                        setFieldValue={setFieldValue}
                      />

                      {touched[CampCenterFields.NOTES] &&
                        errors[CampCenterFields.NOTES] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.NOTES]}
                          </div>
                        )}
                    </div>

                    <div className="w-full mt-[1.5rem]">
                      <label className="text-sm font-bold">Centre Status</label>
                      <div className="flex items-center space-x-4">
                        <Switch
                          ripple={false}
                          checked={campCenterStatus}
                          onChange={() => {
                            setCampCenterStatus(!campCenterStatus);
                            setFieldValue(
                              CampCenterFields.IS_ACTIVE,
                              !campCenterStatus
                            );
                          }}
                          value={values[CampCenterFields.IS_ACTIVE]}
                          className="h-full w-full checked:bg-[#B0E0FF]"
                          containerProps={{
                            className: "w-8 h-4",
                          }}
                          circleProps={{
                            className:
                              "before:hidden left-1 border-none w-[12px] h-[12px]",
                          }}
                        />
                        <span>{campCenterStatus ? "Active" : "Inactive"}</span>
                      </div>

                      {touched[CampCenterFields.IS_ACTIVE] &&
                        errors[CampCenterFields.IS_ACTIVE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampCenterFields.IS_ACTIVE]}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="w-full flex justify-end space-x-4 h-[48px]">
                    <CustomButton
                      name="Reset"
                      type="button"
                      loading={false}
                      disabled={false}
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                        dispatch(setDistrictByState([]));
                      }}
                      className={"min-w-[32%] bg-[#C1C1C1]"}
                    />
                    <CustomButton
                      name="Proceed"
                      type="submit"
                      loading={false}
                      disabled={false}
                      className={"min-w-[32%]"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { AddCampCenterModel };
