import React from "react";

export const Tent = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M13.1455 3.60763L14.6422 1.47637C14.9398 1.05262 14.8375 0.467955 14.4137 0.170393C13.99 -0.127168 13.4053 -0.0248401 13.1077 0.398861L12 1.97634L10.8922 0.398814C10.5946 -0.024887 10.0099 -0.127215 9.58623 0.170347C9.16248 0.467908 9.0602 1.05262 9.35776 1.47633L10.8544 3.60758L0.673497 18.1054C0.232921 18.7365 0 19.4781 0 20.25C0 22.3178 1.68224 24 3.74999 24H20.2499C22.3177 24 23.9999 22.3178 23.9999 20.25C23.9999 19.4781 23.767 18.7365 23.325 18.1033L13.1455 3.60763ZM20.2499 22.125H3.74999C2.71611 22.125 1.87499 21.2839 1.87499 20.25C1.87499 19.8639 1.99115 19.4935 2.2094 19.1808L12 5.23892L21.789 19.1787C22.0088 19.4935 22.1249 19.8639 22.1249 20.25C22.1249 21.2839 21.2838 22.125 20.2499 22.125Z"
          fill="#07284B"
        />
        <path
          d="M10.125 16.5H11.0625V17.4375C11.0625 17.9553 11.4822 18.375 12 18.375C12.5178 18.375 12.9375 17.9553 12.9375 17.4375V16.5H13.875C14.3928 16.5 14.8125 16.0803 14.8125 15.5625C14.8125 15.0447 14.3928 14.625 13.875 14.625H12.9375V13.6875C12.9375 13.1697 12.5178 12.75 12 12.75C11.4822 12.75 11.0625 13.1697 11.0625 13.6875V14.625H10.125C9.60722 14.625 9.1875 15.0447 9.1875 15.5625C9.1875 16.0803 9.60722 16.5 10.125 16.5Z"
          fill="#07284B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2521">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
