/**
 * Beneficiary List of fabrication camps Screen
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectallFabricationCampsBeneficiaryDataList,
  selectFabricationBeneficiaryListCampDistrictId,
  selectFabricationBeneficiaryListCampFilter,
  selectFabricationBeneficiaryListCampStateId,
  selectFabricationBeneficiaryListCurrentPage,
  selectFabricationBeneficiaryListDataPerPage,
  selectFabricationBeneficiaryListNavStatus,
  selectFabricationBeneficiaryListSearchQuery,
} from "../selector";
import { formatDate, manipulateDateAndTime } from "../../../utils";
import Pagination from "../../../components/pagination";
import BeneficiaryJourney from "../../beneficiary_management/components/beneficiaryJourney";
import PersonalDetails from "../../beneficiary_management/components/personalDetails";
import BeneficiaryDetail from "../../beneficiary_management/components/beneficiaryDetail";
import BeneficiaryList from "../../beneficiary_management/components/beneficiaryList";
import { DistributionComponent } from "../../beneficiary_management/components/distributionCard";
import { FabricationComponent } from "../../beneficiary_management/components/fabricationCard";
import { AssessmentReadOnlyComponent } from "../../beneficiary_management/components/assessmentCard";
import BeneficiaryVerificationPart from "../../beneficiary_management/components/beneficiaryVerificationForm";
import {
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
} from "../../beneficiary_management/selector";
import { selectCampUserCounterData } from "../../camp_management/selector";
import {
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setBeneficiaryDetails,
} from "../../beneficiary_management/slice";
import {
  getScheduleCampStepById,
  getStepAndCounterOfUser,
} from "../../camp_management/slice";
import {
  getAllStages,
  getAllStatus,
  selectStages,
  selectStatus,
} from "../../common";
import { useParams } from "react-router-dom";
import {
  allBeneficiaryListOfFabricationCamp,
  setFabricationBeneficiaryListSearchQuery,
} from "../slice";
import { Images } from "../../../constants";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { NoRecord } from "../../../components/noRecords";

function FabricationCampBeneficiayListScreen() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [singleDetailLoading, setSingleDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);

  // handel journey stpes
  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });
  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  const {
    data: beneficiaries = [],
    totalCounts,
    responseCount,
    fabrication_completed,
    fabrication_in_progress,
    fabrication_not_yet_started,
    not_eligible_for_fabrication,
    totalCampBeneficiary,
    totalPages,
    currentPage: currentPageApi,
  } = useSelector(selectallFabricationCampsBeneficiaryDataList) || {};

  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const stageIds =
    useSelector(selectStages).reduce((acc, stage) => {
      acc[stage.beneficiary_stage_name] = stage._id;
      return acc;
    }, {}) || {};
  const userCountersData = useSelector(selectCampUserCounterData) || {};
  const singleBeneficiaryData = useSelector(selectBeneficiaryDetails) || {};

  const singleBeneficiary = beneficiaryDetails || {};
  const search = useSelector(selectFabricationBeneficiaryListSearchQuery) || "";
  const currentPage = useSelector(selectFabricationBeneficiaryListCurrentPage);
  const limit = useSelector(selectFabricationBeneficiaryListDataPerPage);
  const districtId = useSelector(
    selectFabricationBeneficiaryListCampDistrictId
  );
  const stateId = useSelector(selectFabricationBeneficiaryListCampStateId);
  const filter = useSelector(selectFabricationBeneficiaryListCampFilter);
  const navStatus = useSelector(selectFabricationBeneficiaryListNavStatus);

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.assessments?.data[0] // Correct reference here
    );

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0] // Set the first item as the initial value
    )?.status_id?.status_name; // Access status_name after reduce

  // Fabrication data status manage
  const fabrication_data =
    beneficiaryJourney?.stages?.fabrication?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.fabrication?.data[0] // Correct reference here
    );

  const activeCampFlow = allData?.camp_flow?.find(
    (item) => item.is_active === true
  );

  const allStatus =
    useSelector(selectStatus).reduce((acc, status) => {
      acc[status.status_name] = status._id;
      return acc;
    }, {}) || {};

  // Api calling functions
  const getBeneficiaryDataList = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    dispatch(setBeneficiaryDetails({}));
    setLoading(true);
    dispatch(
      allBeneficiaryListOfFabricationCamp({
        scheduled_camp_id: id,
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
          console.log("Beneficiary loaded successfully");
        },
      })
    );
  };

  console.log({ singleBeneficiaryData });
  const getBeneficiaryDetailByUserId = (userId) => {
    dispatch(setBeneficiaryDetails({}));
    setSingleDetailLoading(true);
    dispatch(
      getBeneficiaryDetailById({
        id: userId,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          console.log("loaded successfully");
        },
      })
    );
  };

  const getScheduleCampCampFlowData = (id) => {
    dispatch(
      getScheduleCampStepById({
        id,
        onSuccess: (props) => {
          const { data } = props;
          setAllData(data);
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {
          console.log("Data process loaded successfully");
        },
      })
    );
  };

  const getStatusData = () => {
    dispatch(
      getAllStatus({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  const getUserCounterData = (id) => {
    dispatch(
      getStepAndCounterOfUser({
        scheduled_camp_id: id,
        onSuccess: (props) => {},
      })
    );
  };

  const getStagesData = () => {
    dispatch(
      getAllStages({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      lastStatus: "complete",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus:
        latest_data?.status === "verified"
          ? "complete"
          : latest_data?.status === "rejected"
          ? "in_progress"
          : "",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentReadOnlyComponent
          campSteps={activeCampFlow?.camp_flow_steps}
          campData={campData?.assessment}
          scheduled_camp_id={id}
        />
      ),
      campScheduled:
        assessment_last_Status?.status_id?.status_name ===
        "assessment_in_progress"
          ? campData?.assessment?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.assessment[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.assessment[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } ${
                campData?.assessment[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp`
            : ""
          : "",
      campComplete:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? `Completed on ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.time
            } at ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } Camp`
          : "",
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled:
        allData?.scheduled_camp_id?.camp_type_id?.camp_type_name ===
        "assessment"
          ? false
          : true,
      lastStatus:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? "complete"
          : assessment_last_Status?.status_id?.status_name ===
            "assessment_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: (
        <FabricationComponent
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          stageIds={stageIds}
          fabrication_data={fabrication_data || {}}
        />
      ),
      campScheduled:
        fabrication_data?.status_id?.status_name === "fabrication_in_progress"
          ? `Started on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            } `
          : "",
      campComplete:
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? `Completed on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            }`
          : "",
      campNotSchedled: fabrication_data ? "" : "Not yet scheduled",
      disabled:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? false
          : true,
      lastStatus:
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? "complete"
          : fabrication_data?.status_id?.status_name ===
            "fabrication_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: (
        <DistributionComponent
          campSteps={activeCampFlow}
          campData={campData?.distribtion || [{}]}
          scheduled_camp_id={id}
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          userCountersData={userCountersData}
        />
      ),
      campScheduled:
        campData?.distribution?.length > 0
          ? `Scheduled on ${formatDate(
              campData?.distribution[0]?.scheduled_camp_id?.from_date
            )} at ${
              campData?.distribution[0]?.scheduled_camp_id?.camp_location_id
                ?.camp_location_name
            } ${
              campData?.distribution[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp`
          : "",
      campComplete: ``,
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      disabled:
        allData?.scheduled_camp_id?.camp_type_id?.camp_type_name ===
        "distribution"
          ? false
          : true,
      lastStatus:
        distribution_last_Status === "distribution_complete"
          ? "complete"
          : distribution_last_Status === "distribution_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    }
    if (singleBeneficiaryData?._id) {
      const result = manipulateDateAndTime(singleBeneficiaryData?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiaryData?._id);
      getStatusData();
      getStagesData();
    }
  }, [singleBeneficiaryData]);

  useEffect(() => {
    if (id) {
      getScheduleCampCampFlowData(id);
      getUserCounterData(id);
    }
    getBeneficiaryDataList(
      search,
      stateId,
      districtId,
      navStatus,
      currentPage,
      limit,
      filter
    );
  }, [search, currentPage, stateId, districtId, limit, filter]);

  return (
    <>
      <div className="h-full w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />
          <div className="container p-0 flex flex-col justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            {/* Camp Details  */}
            <div className="w-full p-4 pb-0">
              <div className="flex gap-3 justify-start items-center flex-wrap p-4 bg-[#E5F4FE] rounded-xl border-[#54BBFF] border">
                <div className="flex gap-3 flex-col w-[40%]">
                  <div className="font-bold text-xl title">
                    Camp Code: <span className="text-[#FF5976]">Eetryuio</span>
                  </div>
                  <div className="font-bold text-xl title">
                    Camp Code:{" "}
                    <span className="font-medium text-sm">
                      Daljeet Lodha Daljeet Lodha
                    </span>
                  </div>
                </div>
                <div className="flex gap-3 flex-col">
                  <div className="font-bold text-xl title flex gap-2">
                    Camp Location:
                    <span class="font-medium flex gap-2 items-center text-sm">
                      <LocationMap
                        width={16}
                        height={18}
                        fillColor={"#555555"}
                      />
                      Daljeet Lodha Daljeet Lodha
                      <div>
                        <a
                          href=""
                          class="text-[#256CD0] font-semibold underline"
                        >
                          View Map
                        </a>
                      </div>
                    </span>
                  </div>
                  <div className="font-bold text-xl title flex lg:flex-wrap gap-2 items-center">
                    <span>Held on:</span>

                    <div class="flex lg:flex-wrap justify-between gap-2 items-center text-sm">
                      <span class="font-semibold flex gap-2 items-center">
                        <CalendarNew />
                        <span>April 20, 2024</span>
                      </span>
                      <span class="font-semibold flex gap-2 items-center">
                        <Clock width={16} height={16} />
                        <span>9.00 Am - 6.00 Pm</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Beneficiary Data  */}
            <div className="container  w-full max-w-full px-4 py-2 mt-0 flex flex-row justify-between items-center ">
              <div className="flex flex-row items-center justify-between font-bold text-2xl title max-w-[42%] min-w-[42%]">
                <div className="">Beneficiaries Screened</div>
              </div>
            </div>
            <div className="container p-0 flex w-full px-4  max-w-[42%] min-w-[42%]  ">
              <NavigationBar
                statuses={[
                  `In Progress(${fabrication_in_progress || 0})`,
                  `Not yet started(${fabrication_not_yet_started || 0})`,
                  `Not Eligible(${not_eligible_for_fabrication || 0})`,
                  `Completed(${fabrication_completed || 0})`,
                ]}
                getData={getBeneficiaryDataList}
                beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                barName="fabricationCampBeneficiaryListScreen"
              />
              <div className="col-span-6"></div>
            </div>

            <div className="container  min-h-[50%] flex-auto  flex flex-row justify-between gap-6 w-full p-4  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              <BeneficiaryList
                users={beneficiaries}
                setSearchQuery={setFabricationBeneficiaryListSearchQuery}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={false}
                completionTime={true}
                dateRange={false}
                currentStages={true}
                loading={loading}
              />
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiaryData).length > 0 ? (
                singleBeneficiaryData?.status === "Verified" ? (
                  singleDetailLoading && !singleBeneficiaryData ? (
                    <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                      <Loader />
                    </div>
                  ) : (
                    <div className="container p-0 h-full self-end w-[58%] pr-4">
                      <div
                        className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-6 py-4 max-w-full w-full rounded-xl"
                        ref={scrollableRef}
                      >
                        {/* Beneficiary Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                          <BeneficiaryDetail userData={singleBeneficiary} />
                        </div>

                        {/* Personal Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                          <PersonalDetails userData={singleBeneficiary} />
                        </div>
                        {/* Beneficiary Journey */}
                        <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                          <BeneficiaryJourney
                            beneficiary={singleBeneficiary}
                            steps={steps}
                            beneficiaryJourney={beneficiaryJourney}
                            campData={campData}
                            allData={allData}
                            setExpandedSteps={setExpandedSteps}
                            expandedSteps={expandedSteps}
                            toggleStep={toggleStep}
                          />
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="container h-full self-end pr-4 w-[58%]">
                    This Beneficiary is not Verifyed
                  </div>
                )
              ) : singleDetailLoading ? (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <NoRecord
                    title_1={"Select a Beneficiary to view"}
                    title_2={"Click on the Beneficiaries to view details"}
                  />
                </div>
              )}
            </div>

            <div className=" w-full flex justify-start items-center">
              <Pagination
                name={"fabricationCampBeneficiaryListScreen"}
                totalNumber={totalCounts}
                dataPerPage={responseCount}
                data={getBeneficiaryDataList}
                currentPageApi={currentPageApi}
                perPageOptions={[5, 10, 30, 50, 100]}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { FabricationCampBeneficiayListScreen };
