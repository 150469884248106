/**
 * Date Table Component
 * @format
 */

import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Loader } from "./loader";
import { NoRecord } from "./noRecords";

const DateTableComponent = (props) => {
  const {
    pagination,
    totalRow,
    data,
    currentPages,
    limit,
    setLimit,
    setCurrentPage,
    loading,
    rowsPerPageOptions,
  } = props;
  const columns = useMemo(() => props.apiColumn);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{
        headCells: {
          style: {
            backgroundColor: props.backgroundColor,
            color: "Black",
            fontWeight: "bold",
            fontSize: "14px",
            height: "48px",
          },
        },
      }}
      progressPending={loading}
      progressComponent={
        <div className="h-[500px] w-full flex justify-center items-center bg-customBlue-mainBackground">
          <Loader />
        </div>
      }
      paginationPerPage={limit}
      paginationRowsPerPageOptions={rowsPerPageOptions}
      pagination={pagination}
      paginationServer
      paginationTotalRows={totalRow}
      paginationDefaultPage={currentPages}
      subHeader={false}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      sortIcon={props.sortIcon}
      selectableRows={props.selectableRows}
      dense
      striped
      noDataComponent={
        <div className="h-full py-16">
          <NoRecord title_1={"There are no records to display"} title_2={""} />
        </div>
      }
    />
  );
};

export default DateTableComponent;
