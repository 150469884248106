/**
 * Fabrication Management Selector
 * @format
 */

const fabricationManagement = (state) => state.fabricationManagementReducer;

export const selectallFabricationCampsDataList = (state) =>
  fabricationManagement(state).allFabricationCampsDataList;

export const selectFabricationCurrentPage = (state) =>
  fabricationManagement(state).fabricationCurrentPage;

export const selectFabricationDataPerPage = (state) =>
  fabricationManagement(state).fabricationDataPerPage;

export const selectFabricationSearchQuery = (state) =>
  fabricationManagement(state).fabricationSearchQuery;

export const selectFabricationNavStatus = (state) =>
  fabricationManagement(state).fabricationNavStatus;

// Beneficiary List
export const selectallFabricationCampsBeneficiaryDataList = (state) =>
  fabricationManagement(state).allFabricationCampsBeneficiaryDataList;

export const selectFabricationBeneficiaryListCurrentPage = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListCurrentPage;

export const selectFabricationBeneficiaryListDataPerPage = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListDataPerPage;

export const selectFabricationBeneficiaryListSearchQuery = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListSearchQuery;

export const selectFabricationBeneficiaryListNavStatus = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListNavStatus;

export const selectFabricationBeneficiaryListCampFilter = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListFilter;

export const selectFabricationBeneficiaryListCampStateId = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListStateId;

export const selectFabricationBeneficiaryListCampDistrictId = (state) =>
  fabricationManagement(state).fabricationBeneficiaryListDistrictId;
