/**
 * Counter Card Component for camp overview
 * @flow
 * @format
 */

import React, { useState } from "react";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import { User } from "../../../assets/icons/User";
import StepWithCountsComponent from "./stepWithNumber";
import {
  SelectActiveCampCounter,
  SelectActiveCampStep,
  SelectActiveCurrentCamp,
} from "../selector";
import { useDispatch, useSelector } from "react-redux";
import {
  getBeneficiaryOfCounterOfStepOfCurrentCamp,
  setActiveCampCounter,
  setActiveCampStep,
} from "../slice";

const CounterCardComponent = (props) => {
  const dispatch = useDispatch();
  const { counterData, counterIndex, activeCampId, activeCampStepId } = props;
  const { counter, counts } = counterData;

  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);
  const activeCampStep = useSelector(SelectActiveCampStep);
  const activeCampCounter = useSelector(SelectActiveCampCounter);

  const getBeneficiayOfCounter = (id) => {
    dispatch(
      getBeneficiaryOfCounterOfStepOfCurrentCamp({
        counterId: id,
        onSuccess: () => {},
      })
    );
  };

  const handleCampCounteClick = (counterDetails, counterIndex) => {
    dispatch(
      setActiveCampStep({
        ...activeCampStep,
        isActiveCampStep: false,
      })
    );
    dispatch(
      setActiveCampCounter({
        campCounterIndex: counterIndex,
        campCounterName: `> Counter # ${counterDetails?.counter_number}`,
        campCounterId: counterDetails?._id,
        isActiveCampCounter: true,
      })
    );
    getBeneficiayOfCounter(counterDetails?._id);
  };

  return (
    <>
      <div
        className={`flex flex-wrap justify-between p-2 pt-2 items-center w-full space-y-3 border-b-2 border-[#EBEBEB] ${
          activeCampCounter?.campCounterId === counter?._id &&
          activeCurrentCamp?.campId === activeCampId &&
          activeCampStep?.campStepId === activeCampStepId
            ? "bg-[#DDF1FE]"
            : "bg-[white]"
        }`}
        onClick={(e) => {
          e.preventDefault();
          handleCampCounteClick(counter, counterIndex);
        }}
      >
        <div className="flex justify-between gap-1 items-center w-full">
          <div className=" font-semibold text-WildWatermelon-button ">
            Counter # {counter?.counter_number}
          </div>
          <div className="flex justify-between gap-1 items-center">
            <User width={14} height={14} />
            <div className="font-semibold">{counter?.user_id?.name}</div>
            <ArrowIcon
              width={6}
              height={10}
              // isRotated={isRotated}
              fillColor={"#000000"}
            />
          </div>
        </div>
        {/* progress */}
        <div className="flex flex-wrap justify-between  items-center rounded-lg w-full">
          <StepWithCountsComponent
            data={{
              name: "Completed",
              CountNumber: counts?.completed,
            }}
            colors={{ text: "#256CD0", background: "#DDF1FE" }}
          />
          <StepWithCountsComponent
            data={{
              name: "Waiting",
              CountNumber: counts?.waiting,
            }}
            colors={{ text: "#36D964", background: "#E0F9E7" }}
          />
        </div>
      </div>
    </>
  );
};

export default CounterCardComponent;
