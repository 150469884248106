import React from "react";

export const Pause = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3C5 2.44772 5.44772 2 6 2H7C7.55228 2 8 2.44772 8 3V15C8 15.5523 7.55228 16 7 16H6C5.44772 16 5 15.5523 5 15V3Z"
        fill="white"
      />
      <path
        d="M10 3C10 2.44772 10.4477 2 11 2H12C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16 12 16H11C10.4477 16 10 15.5523 10 15V3Z"
        fill="white"
      />
    </svg>
  );
};
