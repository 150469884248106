import React from "react";

export const NotAllowed = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75737 3.75737C3.20022 4.31453 2.75826 4.97596 2.45673 5.70392C2.1552 6.43188 2 7.2121 2 8.00004C2 8.78797 2.1552 9.56819 2.45673 10.2962C2.75826 11.0241 3.20021 11.6855 3.75737 12.2427C4.31452 12.7999 4.97596 13.2418 5.70392 13.5433C6.43188 13.8449 7.2121 14.0001 8.00004 14.0001C8.78797 14.0001 9.56819 13.8449 10.2962 13.5433C11.0241 13.2418 11.6855 12.7999 12.2427 12.2427M3.75737 3.75737C4.31453 3.20022 4.97596 2.75826 5.70392 2.45673C6.43188 2.1552 7.2121 2 8.00004 2C8.78797 2 9.56819 2.1552 10.2962 2.45673C11.0241 2.75826 11.6855 3.20021 12.2427 3.75737C12.7999 4.31452 13.2418 4.97596 13.5433 5.70392C13.8449 6.43188 14.0001 7.2121 14.0001 8.00004C14.0001 8.78797 13.8449 9.56819 13.5433 10.2962C13.2418 11.0241 12.7999 11.6855 12.2427 12.2427M3.75737 3.75737L8.00004 8.00004L12.2427 12.2427"
        stroke="#FF0E0E"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
