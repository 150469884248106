/**
 * All Camp list Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { HeaderScreen, Sidebar } from "../../../components";
import { Images } from "../../../constants";
import { Date } from "../../../assets/icons/Date";
import { Clock } from "../../../assets/icons/Clock";
import { MultipleUser } from "../../../assets/icons/MultipleUser";
import { Link, useNavigate } from "react-router-dom";
import { LeftArrow } from "../../../assets/icons/LeftArrow";
import { RightArrow } from "../../../assets/icons/RightArrow";
import { CalendarNew } from "../../../assets/icons/Calender";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import {
  getAllMyCampsList,
  getStepAndCounterOfUser,
  updateToggleForAvailableUnavailable,
} from "../slice";
import { useDispatch, useSelector } from "react-redux";
import { selectAllMyCamps } from "../selector";
import { formatDate } from "../../../utils";

const MyCampsDashboardScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    pastCamps,
    currentCamps: today_camps = [],
    upcomingCamps,
  } = useSelector(selectAllMyCamps);
  const [currentCampIndex, setCurrentCampIndex] = useState(0);

  // functions
  const getMyCampsData = () => {
    dispatch(getAllMyCampsList());
  };

  useEffect(() => {
    getMyCampsData();
  }, []);

  const todayTotalCamps = today_camps.length || [];

  const handleNextCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex < todayTotalCamps - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const currentCamp = today_camps[currentCampIndex];

  return (
    <>
      <div className="h-screen min-w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-65px)]">
          <Sidebar />
          {/* w-[calc(100vw-90px)] */}
          <div className="flex flex-col h-full p-4 bg-customBlue-mainBackground w-screen">
            <div className="container min-h-[80px] min-w-full grid grid-cols-12 gap-4 items-start justify-between h-full">
              {/* Left Side */}
              <div className="col-span-9 flex flex-col bg-none rounded-lg font-raleway space-y-4 overflow-y-auto scrollbar-hide h-full">
                {/* Today's camps */}
                <div className="w-full bg-none rounded-xl p-0">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-xl font-bold">
                      Today’s/Ongoing's Camps for me
                    </h3>
                    {todayTotalCamps > 0 ? (
                      <div className="text-sm whitespace-nowrap">
                        <button
                          onClick={handlePrevCamp}
                          disabled={currentCampIndex === 0}
                          className=" font-bold"
                        >
                          <LeftArrow width={6} height={11} />
                        </button>
                        <span className="px-2 text-sm">{`${
                          currentCampIndex + 1
                        } / ${todayTotalCamps}`}</span>
                        <button
                          onClick={handleNextCamp}
                          disabled={currentCampIndex === todayTotalCamps - 1}
                          className=" font-bold"
                        >
                          <RightArrow width={6} height={11} />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {todayTotalCamps > 0 ? (
                    <>
                      <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-xl border-2 border-customBlue-border gap-3">
                        <div className="flex justify-between w-full items-center">
                          <h3 className="font-bold">
                            {
                              currentCamp?.scheduled_camp_id?.camp_location_id
                                ?.camp_location_name
                            }
                          </h3>
                          <div className="flex gap-3">
                            <span className="font-semibold">
                              Counter No:{" "}
                              <span className="text-[#FF5976] font-bold">
                                {currentCamp?.counter_number}
                              </span>
                            </span>
                            <span className="font-semibold">
                              Stage:{" "}
                              <span className="text-[#FF5976] font-bold">
                                {" "}
                                {currentCamp?.camp_step_id?.camp_step_name}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-wrap w-full gap-2 text-sm">
                          <div className="border-2 border-gray-100 flex-1 p-2 rounded-lg flex flex-col space-y-4">
                            <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                              <div className="flex flex-row justify-start items-center space-x-4">
                                <h3 className="font-bold whitespace-nowrap">
                                  Camp Center:
                                </h3>
                                <span className="font-semibold">
                                  {
                                    currentCamp?.scheduled_camp_id
                                      ?.camp_location_id?.camp_location_name
                                  }
                                </span>
                              </div>
                              <div className="flex gap-1 flex-wrap items-center">
                                <span className="font-semibold">
                                  <span className="text-gray-tone-divider">
                                    |
                                  </span>
                                </span>

                                <span className="font-semibold flex gap-2 items-center">
                                  <LocationMap
                                    width={16}
                                    height={18}
                                    fillColor={"#00000080"}
                                  />
                                  {
                                    currentCamp?.scheduled_camp_id
                                      ?.camp_location_id?.address_1
                                  }
                                  , Dist-
                                  {
                                    currentCamp?.scheduled_camp_id
                                      ?.camp_location_id?.district
                                      ?.district_name
                                  }
                                  .
                                  <div>
                                    <a
                                      href=""
                                      className="text-[#256CD0] font-semibold underline"
                                    >
                                      View Map
                                    </a>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="flex lg:flex-wrap  gap-2 w-full items-center">
                              <h3 className="font-bold whitespace-nowrap">
                                Contact Person:
                              </h3>
                              <div className="flex gap-1 flex-wrap items-center">
                                <span className="font-semibold">
                                  {
                                    currentCamp?.scheduled_camp_id
                                      ?.camp_location_id?.contact_person_name
                                  }
                                  ,{" "}
                                  <span className="underline">
                                    {
                                      currentCamp?.scheduled_camp_id
                                        ?.camp_location_id?.contact_person_phone
                                    }
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-lg border-2 border-gray-100 ">
                            <div className="flex justify-between w-full items-center">
                              <div className="flex flex-col gap-3">
                                <div className="font-semibold">
                                  <span className="font-bold me-3">
                                    Camp Type:
                                  </span>
                                  {
                                    currentCamp?.scheduled_camp_id?.camp_type_id
                                      ?.camp_type_name
                                  }
                                </div>
                                <div className="font-bold">
                                  Camp Code:{" "}
                                  <span className="text-[#FF5976] ms-3">
                                    {" "}
                                    {currentCamp?.scheduled_camp_id?.camp_code}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap w-full gap-2 text-sm">
                          <div className="flex-1 p-2 rounded-lg bg-[#EFF9FF]">
                            <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                              <span className="font-semibold">
                                Scheduled Beneficiaries{" "}
                                <span className="text-[#256CD0] py-1 px-2 bg-[#DDF1FE] rounded font-bold">
                                  {currentCamp?.beneficiaryCount}
                                </span>
                              </span>

                              <span className="font-semibold flex gap-2 items-center">
                                <CalendarNew
                                  width={16}
                                  height={18}
                                  fillColor={"#00000080"}
                                />
                                {formatDate(
                                  currentCamp?.scheduled_camp_id?.from_date
                                )}
                              </span>
                              <span className="font-semibold flex gap-2 items-center">
                                <Clock
                                  width={16}
                                  height={18}
                                  fillColor={"#00000080"}
                                />
                                {currentCamp?.scheduled_camp_id?.from_time}-
                                {currentCamp?.scheduled_camp_id?.to_time}
                              </span>
                            </div>
                          </div>

                          <button
                            className="p-2 px-4 bg-[#FF5976] text-white rounded-lg font-semibold"
                            onClick={() => {
                              if (currentCamp?.scheduled_camp_id?._id) {
                                dispatch(
                                  getStepAndCounterOfUser({
                                    scheduled_camp_id:
                                      currentCamp?.scheduled_camp_id?._id,
                                    onSuccess: (props) => {
                                      const { data } = props;
                                      // if (!data?.is_active) {
                                      dispatch(
                                        updateToggleForAvailableUnavailable({
                                          id: data?.counter_id,
                                          onSuccess: (props) => {
                                            navigate(
                                              `/myCamp/${currentCamp?.scheduled_camp_id?._id}`,
                                              {
                                                replace: true,
                                              }
                                            );
                                          },
                                        })
                                      );
                                      // }
                                    },
                                  })
                                );
                              }
                            }}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <span className="text-center text-base font-medium">
                        There isn’t any Today Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
                {/* Upcoming Camps */}
                <div className="w-full bg-none rounded-xl p-0">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-xl font-bold">Upcoming Camps for me</h3>
                    <div className="text-sm whitespace-nowrap">
                      {upcomingCamps?.length} Camps
                    </div>
                  </div>
                  {upcomingCamps?.length > 0 ? (
                    <div className="flex flex-col space-y-4 ">
                      {upcomingCamps?.map((upcomingCamp, upcomingCampIndex) => (
                        <>
                          <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-xl border-2 border-customBlue-border gap-3">
                            <div className="flex justify-between w-full items-center">
                              <h3 className="font-bold">
                                {
                                  upcomingCamp?.scheduled_camp_id
                                    ?.camp_location_id?.camp_location_name
                                }
                              </h3>
                              <div className="flex gap-3">
                                <span className="font-semibold">
                                  Counter No:{" "}
                                  <span className="text-[#FF5976] font-bold">
                                    {upcomingCamp?.counter_number}
                                  </span>
                                </span>
                                <span className="font-semibold">
                                  Stage:{" "}
                                  <span className="text-[#FF5976] font-bold">
                                    {" "}
                                    {
                                      upcomingCamp?.camp_step_id
                                        ?.camp_step_display_name
                                    }
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-wrap w-full gap-2 text-sm">
                              <div className="border-2 border-gray-100 flex-1 p-2 rounded-lg flex flex-col space-y-4">
                                <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                                  <div className="flex flex-row justify-start items-center space-x-4">
                                    <h3 className="font-bold whitespace-nowrap">
                                      Camp Center:
                                    </h3>
                                    <span className="font-semibold">
                                      {
                                        upcomingCamp?.scheduled_camp_id
                                          ?.camp_location_id?.camp_location_name
                                      }
                                    </span>
                                  </div>
                                  <div className="flex gap-1 flex-wrap items-center">
                                    <span className="font-semibold">
                                      <span className="text-gray-tone-divider">
                                        |
                                      </span>
                                    </span>

                                    <span className="font-semibold flex gap-2 items-center">
                                      <LocationMap
                                        width={16}
                                        height={18}
                                        fillColor={"#00000080"}
                                      />
                                      {
                                        upcomingCamp?.scheduled_camp_id
                                          ?.camp_location_id?.address_1
                                      }
                                      , Dist-
                                      {
                                        upcomingCamp?.scheduled_camp_id
                                          ?.camp_location_id?.district
                                          ?.district_name
                                      }
                                      .
                                      <div>
                                        <a
                                          href=""
                                          className="text-[#256CD0] font-semibold underline"
                                        >
                                          View Map
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="flex lg:flex-wrap  gap-2 w-full items-center">
                                  <h3 className="font-bold whitespace-nowrap">
                                    Contact Person:
                                  </h3>
                                  <div className="flex gap-1 flex-wrap items-center">
                                    <span className="font-semibold">
                                      {
                                        upcomingCamp?.scheduled_camp_id
                                          ?.camp_location_id
                                          ?.contact_person_name
                                      }
                                      ,{" "}
                                      <span className="underline">
                                        {
                                          upcomingCamp?.scheduled_camp_id
                                            ?.camp_location_id
                                            ?.contact_person_phone
                                        }
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-lg border-2 border-gray-100 ">
                                <div className="flex justify-between w-full items-center">
                                  <div className="flex flex-col gap-3">
                                    <div className="font-semibold">
                                      <span className="font-bold me-3">
                                        Camp Type:
                                      </span>
                                      {
                                        upcomingCamp?.scheduled_camp_id
                                          ?.camp_type_id?.camp_type_name
                                      }
                                    </div>
                                    <div className="font-bold">
                                      Camp Code:{" "}
                                      <span className="text-[#FF5976] ms-3">
                                        {" "}
                                        {
                                          upcomingCamp?.scheduled_camp_id
                                            ?.camp_code
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-wrap w-full gap-2 text-sm">
                              <div className="flex-1 p-2 rounded-lg bg-[#EFF9FF]">
                                <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                                  <span className="font-semibold">
                                    Scheduled Beneficiaries{" "}
                                    <span className="text-[#256CD0] py-1 px-2 bg-[#DDF1FE] rounded font-bold">
                                      {upcomingCamp?.beneficiaryCount}
                                    </span>
                                  </span>

                                  <span className="font-semibold flex gap-2 items-center">
                                    <CalendarNew
                                      width={16}
                                      height={18}
                                      fillColor={"#00000080"}
                                    />
                                    {formatDate(
                                      upcomingCamp?.scheduled_camp_id?.from_date
                                    )}
                                  </span>
                                  <span className="font-semibold flex gap-2 items-center">
                                    <Clock
                                      width={16}
                                      height={18}
                                      fillColor={"#00000080"}
                                    />
                                    {upcomingCamp?.scheduled_camp_id?.from_time}
                                    -{upcomingCamp?.scheduled_camp_id?.to_time}
                                  </span>
                                </div>
                              </div>

                              <button className="flex flex-row justify-between items-center space-x-2 p-2 px-4 bg-[#4AB8FF] text-white rounded-lg font-semibold">
                                <span>View Beneficiaries</span>
                                <ArrowIcon
                                  width={6}
                                  height={10}
                                  // isRotated={isRotated}
                                  fillColor={"#ffffff"}
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <span className="text-center text-base font-medium">
                        There isn’t any Upcoming Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/* Right Side */}
              <div className="col-span-3 flex flex-col space-y-4 justify-start font-raleway text-2xl h-full overflow-y-auto scrollbar-hide">
                {/* my Past Camps */}
                <div className="w-full bg-white p-6 border-2 border-gray-100">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-xl font-bold">My Past Camps</h3>
                  </div>
                  {pastCamps?.length > 0 ? (
                    pastCamps?.map((pastCamp, campIndex) => (
                      <div
                        className="rounded-xl mb-4 p-4 border border-gray-100"
                        key={campIndex}
                      >
                        <div className="py-0 border-b ">
                          <h4 className="text-base font-bold mb-2">
                            {
                              pastCamp?.scheduled_camp_id?.camp_type_id
                                ?.camp_type_name
                            }
                          </h4>
                        </div>
                        <div className="flex flex-row justify-between items-center text-sm text-black-700 mb-2 py-2">
                          <strong>
                            {
                              pastCamp?.scheduled_camp_id?.camp_location_id
                                ?.camp_location_name
                            }
                          </strong>
                          <Link href="#" className="text-activeLink underline">
                            View Map
                          </Link>
                        </div>
                        <div className="flex flex-row space-x-2 items-center">
                          <LocationMap
                            height={18}
                            width={15}
                            fillColor={"#000000"}
                          />
                          <p className="text-sm">
                            {
                              pastCamp?.scheduled_camp_id?.camp_location_id
                                ?.address_1
                            }
                            , Dist-
                            {
                              pastCamp?.scheduled_camp_id?.camp_location_id
                                ?.district?.district_name
                            }
                            .
                          </p>
                        </div>
                        <div className="flex flex-row flex-wrap justify-start items-center bg-gray-100 p-0 rounded-xl my-4 text-sm">
                          <span className="p-2 flex-1">
                            Stage:{" "}
                            <strong>
                              {pastCamp?.camp_step_id?.camp_step_display_name}
                            </strong>
                          </span>
                          <span className="p-2 flex-1">
                            Counter No:{" "}
                            <strong>{pastCamp?.counter_number}</strong>
                          </span>
                        </div>
                        <div className="text-sm text-gray-700">
                          <div className="flex items-center mb-2">
                            <span
                              role="img"
                              aria-label="calendar"
                              className="mr-2"
                            >
                              <Date width={18} height={18} />
                            </span>
                            {formatDate(pastCamp?.scheduled_camp_id?.from_date)}
                          </div>
                          <div className="flex items-center mb-2">
                            <span
                              role="img"
                              aria-label="clock"
                              className="mr-2"
                            >
                              <Clock width={16} height={17} />
                            </span>
                            {pastCamp?.scheduled_camp_id?.from_time}-
                            {pastCamp?.scheduled_camp_id?.to_time}
                          </div>
                          <div className="flex items-center mb-2">
                            <span
                              role="img"
                              aria-label="users"
                              className="mr-2"
                            >
                              <MultipleUser width={18} height={20} />
                            </span>
                            {pastCamp?.beneficiaryCount} Beneficiaries
                          </div>
                          <div className="flex lg:flex-wrap  gap-2 w-full items-center">
                            <h3 className="whitespace-nowrap">
                              Contact Person:
                            </h3>
                            <div className="flex gap-1 flex-wrap items-center">
                              <span className="font-semibold">
                                {
                                  pastCamp?.scheduled_camp_id?.camp_location_id
                                    ?.contact_person_name
                                }
                                ,{" "}
                                <span className="underline font-bold">
                                  {
                                    pastCamp?.scheduled_camp_id
                                      ?.camp_location_id?.contact_person_phone
                                  }
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <img
                        src={Images.upcomingCampImage}
                        alt="Upcoming Camp"
                        className="w-full h-full "
                      />
                      <span className="text-center text-base font-medium">
                        There isn’t any Past Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MyCampsDashboardScreen };
