/**
 * Assessment Components
 * @format
 */

import React, { useEffect, useState } from "react";
import { getFieldBox, manipulateDateAndTime } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  getStepAndCounterOfUser,
  updateRequiredDataOfCampSteps,
} from "../../camp_management/slice";
import { selectCampUserCounterData } from "../../camp_management/selector";
import CustomButton from "../../../components/customButtion";

export function AssessmentComponent(props) {
  const dispatch = useDispatch();
  const {
    campSteps,
    campData,
    beneficiary_id,
    scheduled_camp_id,
    status,
    componentName,
  } = props;
  const [userCountersData, setUserCountersData] = useState({});

  // State to hold a modifiable copy of campSteps
  const [localCampSteps, setLocalCampSteps] = useState([]);
  // const userCountersData = useSelector(selectCampUserCounterData) || {};

  // Process and clone campSteps on initial load
  // console.log({props});

  useEffect(() => {
    const counterData = campData?.[0]?.camp_flow?.filter(
      (item) => item?.step_status === "completed"
    );

    const clonedCampSteps = campSteps?.map((step) => {
      const matchingCounter = counterData.find(
        (counter) =>
          counter?.camp_step_id?._id === step?.camp_step_id?.camp_step_id?._id
      );

      return {
        ...step,
        data: matchingCounter ? matchingCounter?.data : null,
      };
    });

    setLocalCampSteps(clonedCampSteps);
    if (campData?.[0]?.scheduled_camp_id?._id) {
      getUserCounterData(campData?.[0]?.scheduled_camp_id?._id);
    }
  }, [campSteps, campData]);

  const [selected, setSelected] = useState(null);
  const [openStepBody, setOpenStepBody] = useState(null);
  const [initialObject, setInitialObject] = useState({});

  const getUserCounterData = (id) => {
    dispatch(
      getStepAndCounterOfUser({
        scheduled_camp_id: id,
        onSuccess: (props) => {
          setUserCountersData(props?.data);
        },
      })
    );
  };

  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };

  // Extract initial values from localCampSteps and set them
  useEffect(() => {
    const initialValues = {};

    localCampSteps?.forEach((stepItem) => {
      stepItem?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
        const fieldName =
          fieldItem?.field_box === "textArea"
            ? `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
            : fieldItem?.field_name;

        initialValues[fieldName] = stepItem?.data?.[fieldName] || "";
      });
    });

    setInitialObject(initialValues);
    setSelected(initialValues?.checked_in_person ?? selected);
  }, [localCampSteps]);
  // console.log({ status });

  return (
    <Formik
      initialValues={initialObject}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const { step, ...restValues } = values;
        // const counter = campData[0]?.camp_flow?.find((item) => {
        //   return (
        //     item?.camp_step_id?._id === step?.camp_step_id?.camp_step_id?._id &&
        //     item?.step_status === "assigned"
        //   );
        // });
        let statusId = "";

        if (componentName === "distribution") {
          statusId =
            step?.step_type?.camp_step_type_name === "initial" ||
            step?.step_type?.camp_step_type_name === "intermediate"
              ? status?.distribution_in_progress
              : step?.step_type?.camp_step_type_name === "final"
              ? status?.distribution_completed
              : null;
        } else if (componentName === "assessment") {
          statusId =
            step?.step_type?.camp_step_type_name === "initial" ||
            step?.step_type?.camp_step_type_name === "intermediate"
              ? status?.assessment_in_progress
              : step?.step_type?.camp_step_type_name === "final"
              ? status?.assessement_completed
              : null;
        }
        // const statusId =
        //   step?.step_type?.camp_step_type_name === "initial" ||
        //   step?.step_type?.camp_step_type_name === "intermediate"
        //     ? status?.assessment_in_progress
        //     : step?.step_type?.camp_step_type_name === "final"
        //     ? status?.assessement_completed
        //     : null;

        const filteredData = {};
        step?.camp_step_id?.required_data_id?.forEach((fieldItem) => {
          const fieldName =
            fieldItem?.field_box === "textArea"
              ? `${step?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
              : fieldItem?.field_name;

          if (restValues[fieldName]) {
            filteredData[fieldName] = restValues[fieldName];
          }
        });

        const updateValues = {
          beneficiary_id,
          scheduled_camp_id,
          current_status: statusId,
          camp_flow: {
            camp_step_id: values?.step?.camp_step_id?.camp_step_id?._id,
            camp_counter_id: userCountersData?.counter_id,
            step_status: "completed",
            data: filteredData,
          },
        };
        // console.log({ updateValues });

        dispatch(
          updateRequiredDataOfCampSteps({
            values: updateValues,
            onSuccess: (props) => {
              console.log({ props });
            },
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form
          onSubmit={handleSubmit}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     e.preventDefault();
          //     handleSubmit();
          //   }
          // }}
        >
          <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
            {localCampSteps?.map((stepItem, stepIndex) => {
              const isCheckinStep =
                userCountersData?.step ===
                stepItem?.camp_step_id?.camp_step_id?.camp_step_name;

              return (
                <div className="w-full py-3" key={stepIndex}>
                  <label
                    className={`cursor-pointer font-bold text-base ${
                      isCheckinStep ? "" : "text-gray-500"
                    }`}
                    onClick={() => isCheckinStep && toggleStep(stepIndex)}
                  >
                    {`Step ${stepIndex + 1}: ${
                      stepItem?.camp_step_id?.camp_step_id
                        ?.camp_step_display_name
                    }`}
                  </label>
                  {openStepBody === stepIndex && isCheckinStep && (
                    <div className="my-2">
                      {stepItem?.camp_step_id?.required_data_id?.map(
                        (fieldItem, fieldIndex) => {
                          let props = {};
                          if (fieldItem?.field_box === "radio") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              setFieldValue,
                              setSelected,
                              selected,
                              isVerified: stepItem?.data ? true : false,
                              campStep:
                                stepItem?.camp_step_id?.camp_step_id?._id,
                            };
                          } else if (fieldItem?.field_box === "textArea") {
                            props = {
                              name: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                              id: fieldItem?.field_name,
                              onChange: handleChange,
                              disabled:
                                values[
                                  `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                ],
                              placeholder: "",
                              value:
                                values[
                                  `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`
                                ],
                              className: "",
                            };
                          } else if (fieldItem?.field_box === "inputText") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              onChange: handleChange,
                              type: "text",
                              placeholder: "",
                              value: values[fieldItem?.field_name],
                              className: "",
                            };
                          } else if (fieldItem?.field_box === "singleSelect") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              options: fieldItem?.option_id?.map((item) => ({
                                label: item?.state_name,
                                value: item?._id,
                              })),
                              value:
                                fieldItem?.option_id
                                  ?.map((item) => ({
                                    label: item?.state_name,
                                    value: item?._id,
                                  }))
                                  ?.find(
                                    (item) =>
                                      item?.value ===
                                      values[fieldItem?.field_name]
                                  ) || null,
                              onChange: (selectedOption) => {
                                const selectedAccess =
                                  selectedOption?.value || "";
                                setFieldValue(
                                  fieldItem?.field_name,
                                  selectedAccess
                                );
                              },
                              touched: touched,
                              errors: errors,
                              disabled: values[fieldItem?.field_name],
                              placeholder: "Select",
                              isSearchable: true,
                              isClearable: false,
                            };
                          } else if (fieldItem?.field_box === "multiSelect") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              displayValue: "label",
                              showCheckbox: true,
                              showArrow: true,
                              placeholder: "Select",
                              disabled: values[fieldItem?.field_name],
                              options: fieldItem?.option_id?.map((item) => ({
                                label: item?.option_display_name,
                                value: item?._id,
                              })),
                              onSelect: (selectedList) =>
                                setFieldValue(
                                  fieldItem?.field_name,
                                  selectedList.map((item) => item.value)
                                ),
                              onRemove: (selectedList) =>
                                setFieldValue(
                                  fieldItem?.field_name,
                                  selectedList.map((item) => item.value)
                                ),
                              selectedValues: fieldItem?.option_id
                                ?.map((item) => ({
                                  label: item?.option_display_name,
                                  value: item?._id,
                                }))
                                .filter((option) =>
                                  values[fieldItem?.field_name]?.includes(
                                    option.value
                                  )
                                ),
                            };
                          }

                          return (
                            <div
                              key={fieldIndex}
                              className="flex flex-col space-y-2 items-start mb-2 w-full"
                            >
                              <div className="w-full text-black font-semibold">
                                {fieldItem?.field_display_name}
                              </div>
                              <div className="w-full">
                                {getFieldBox(fieldItem?.field_box, props)}
                              </div>
                            </div>
                          );
                        }
                      )}
                      <CustomButton
                        name="Save"
                        type="submit"
                        loading={false}
                        disabled={stepItem?.data}
                        onClick={() => setFieldValue("step", stepItem)}
                        className={"text-white mt-2"}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </form>
      )}
    </Formik>
  );
}

export function AssessmentReadOnlyComponent(props) {
  const dispatch = useDispatch();
  const { campSteps, campData } = props;

  // State to hold a modifiable copy of campSteps
  const [localCampSteps, setLocalCampSteps] = useState([]);

  // Process and clone campSteps on initial load
  useEffect(() => {
    const counterData = campData?.[0]?.camp_flow?.filter(
      (item) => item?.step_status === "completed"
    );

    const clonedCampSteps = campSteps?.map((step) => {
      const matchingCounter = counterData.find(
        (counter) =>
          counter?.camp_step_id?._id === step?.camp_step_id?.camp_step_id?._id
      );

      return {
        ...step,
        data: matchingCounter ? matchingCounter?.data : null,
        stepDataCreatedAt: matchingCounter ? matchingCounter?.createdAt : null,
        stepDataCreatedBy: matchingCounter ? matchingCounter?.created_by : null,
      };
    });

    setLocalCampSteps(clonedCampSteps);
  }, [campSteps, campData]);

  const [selected, setSelected] = useState(null);
  const [openStepBody, setOpenStepBody] = useState(null);

  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
        {localCampSteps?.map((stepItem, stepIndex) => {
          const stepName = stepItem?.camp_step_id?.camp_step_id?.camp_step_name;
          return (
            <div className="w-full py-3" key={stepIndex}>
              <label
                className={`cursor-pointer font-bold text-base `}
                onClick={() => toggleStep(stepIndex)}
              >
                {`Step ${stepIndex + 1}: ${
                  stepItem?.camp_step_id?.camp_step_id?.camp_step_display_name
                }`}
              </label>
              {openStepBody === stepIndex && (
                <>
                  {stepItem?.data ? (
                    <div className="my-2 text-sm font-Inter text-[#7e7c7c]">
                      {`Completed on  ${
                        manipulateDateAndTime(stepItem?.stepDataCreatedAt)?.date
                      } at ${
                        manipulateDateAndTime(stepItem?.stepDataCreatedAt)?.time
                      } by ${stepItem?.stepDataCreatedBy?.name} (Counter # ${
                        stepItem?.number_of_counters
                      })`}
                    </div>
                  ) : (
                    <div className="my-2">
                      {stepItem?.camp_step_id?.required_data_id?.map(
                        (fieldItem, fieldIndex) => {
                          let props = {};
                          if (fieldItem?.field_box === "radio") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              setSelected,
                              selected,
                              isVerified: true,
                              campStep:
                                stepItem?.camp_step_id?.camp_step_id?._id,
                            };
                          } else if (fieldItem?.field_box === "textArea") {
                            props = {
                              name: `${stepItem?.camp_step_id?.camp_step_id?.camp_step_name}_${fieldItem?.field_name}`,
                              id: fieldItem?.field_name,
                              disabled: true,
                              placeholder: "",
                              className: "",
                            };
                          } else if (fieldItem?.field_box === "inputText") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              type: "text",
                              placeholder: "",
                              className: "",
                            };
                          } else if (fieldItem?.field_box === "singleSelect") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              options: fieldItem?.option_id?.map((item) => ({
                                label: item?.state_name,
                                value: item?._id,
                              })),
                              onChange: (selectedOption) => {
                                const selectedAccess =
                                  selectedOption?.value || "";
                              },
                              disabled: true,
                              placeholder: "Select",
                              isSearchable: true,
                              isClearable: false,
                            };
                          } else if (fieldItem?.field_box === "multiSelect") {
                            props = {
                              name: fieldItem?.field_name,
                              id: fieldItem?.field_name,
                              displayValue: "label",
                              showCheckbox: true,
                              showArrow: true,
                              placeholder: "Select",
                              disabled: true,
                              options: fieldItem?.option_id?.map((item) => ({
                                label: item?.option_display_name,
                                value: item?._id,
                              })),
                              onSelect: (selectedList) => {},
                              onRemove: (selectedList) => {},
                            };
                          }

                          return (
                            <div
                              key={fieldIndex}
                              className="flex flex-col space-y-2 items-start mb-2 w-full"
                            >
                              <div className="w-full text-black font-semibold">
                                {fieldItem?.field_display_name}
                              </div>
                              <div className="w-full">
                                {getFieldBox(fieldItem?.field_box, props)}
                              </div>
                            </div>
                          );
                        }
                      )}
                      <CustomButton
                        name="Save"
                        type="button"
                        loading={false}
                        disabled={true}
                        className={"text-white mt-2"}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
