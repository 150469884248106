/**
 * Upcoming Card Components
 * @format
 */

import React, { useState } from "react";
import * as Yup from "yup";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { formatDate } from "../../../utils";
import { Formik } from "formik";
import CustomButton from "../../../components/customButtion";
import { CrossCircle } from "../../../assets/icons/CrossCircle";
import { CheckCircle } from "../../../assets/icons/CheckCircle";
import { useDispatch } from "react-redux";
import { scheduleOrReScheduleBeneficiaryOfCamp } from "../slice";
import { Cross } from "../../../assets/icons/Cross";

const UpcomingCampsListCard = (props) => {
  const dispatch = useDispatch();
  const { dataList, beneficiary_id, toggleStep } = props;
  const [campChecked, setCampChecked] = useState(null);

  const initialValues = {
    selectedCampId: "",
  };

  const validationSchema = Yup.object().shape({
    selectedCampId: Yup.string().required("Please select a camp"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          // console.log(values.selectedCampId, beneficiary_id);
          dispatch(
            scheduleOrReScheduleBeneficiaryOfCamp({
              scheduled_camp_id: values.selectedCampId,
              beneficiary_id,
            })
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="container bg-white p-0 mb-3 font-DM">
              <div className="flex flex-row flex-1 mb-3 gap-5 flex-wrap bg-[#E8F6FF] p-3">
                <div className="card w-full">
                  <div className="flex flex-row justify-between items-center text-[#181818] font-bold text-base xl:mb-4 mb-3">
                    <div> Upcoming Camps</div>
                    <div
                      className="cursor-pointer text-xl font-semibold"
                      onClick={() => {
                        toggleStep("assessment");
                      }}
                    >
                      <Cross width={25} height={25} />
                    </div>
                  </div>
                  <div className="w-full space-y-4">
                    {dataList?.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className="bg-white flex flex-col font-medium gap-3 overflow-auto p-3 rounded-lg xl:text-lg"
                      >
                        <div
                          className={`border-2 flex flex-col gap-1.5 p-3 rounded-lg text-sm xl:p-5 ${
                            campChecked === item._id ? "border-[#0162DD]" : ""
                          }`}
                        >
                          <div className="flex flex-row justify-between items-center gap-2 flex-wrap md:flex-nowrap">
                            <div className="font-semibold">
                              {item?.camp_type_id?.camp_type_display_name}
                            </div>
                            <div className="flex">
                              <h3 className="whitespace-nowrap">Camp Code:</h3>
                              <span className="font-semibold">
                                {item?.camp_code}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-row justify-start items-center gap-2 flex-wrap md:flex-nowrap">
                            <h3 className="whitespace-nowrap text-[#666]">
                              Camp Center:
                            </h3>
                            <span className="font-semibold">
                              {item?.camp_location_id?.camp_location_name}
                            </span>
                          </div>

                          <div className="flex gap-1 items-center">
                            <span className="flex gap-2 items-center text-[#666]">
                              <LocationMap
                                width={16}
                                height={18}
                                fillColor={"#808080"}
                                fillColorOpacity={"1"}
                              />
                              {`${item?.camp_location_id?.address_1}, Dist- ${item?.camp_location_id?.district?.district_name}.`}
                            </span>
                          </div>
                          <div className="flex lg:flex-wrap justify-between items-center">
                            <div className="flex lg:flex-wrap justify-start gap-4 items-center text-[#666] leading-normal">
                              <span className="font-semibold flex gap-2 items-center">
                                <CalendarNew />
                                <span>{formatDate(item?.from_date)}</span>
                              </span>
                              <span className="font-semibold flex gap-2 items-center">
                                <Clock width={16} height={16} />
                                <span>{`${item?.from_time} - ${item?.to_time}`}</span>
                              </span>
                            </div>
                            <span className="w-fit">
                              <input
                                type="radio"
                                name="selectedCampId"
                                value={item._id}
                                checked={campChecked === item._id}
                                onChange={() => {
                                  setCampChecked(item._id);
                                  setFieldValue("selectedCampId", item._id);
                                }}
                                className="h-5 w-5"
                              />
                              {touched.selectedCampId &&
                                errors.selectedCampId && (
                                  <div className="mt-0 mb-2 text-xs text-red-500">
                                    {errors.selectedCampId}
                                  </div>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="container flex flex-row justify-center items-end">
                    <div className="flex flex-row justify-center items-end w-full h-full mt-[20px]">
                      <div className="gap-[32px] flex justify-between">
                        <div>
                          <CustomButton
                            type="button"
                            name="Cancel"
                            onClick={() => {
                              resetForm();
                              setCampChecked(null);
                            }}
                            disabled={false}
                            className="flex justify-center items-center px-[32px] py-3 w-full h-full bg-black text-white font-bold rounded-xl"
                            icon={
                              <span className="mr-2">
                                <CrossCircle width={17} height={16} />
                              </span>
                            }
                            loading={false}
                          />
                        </div>
                        <div>
                          <CustomButton
                            name="Schedule"
                            type="submit"
                            loading={false}
                            disabled={!campChecked}
                            className="flex justify-center items-center px-[32px] py-2 w-full h-full bg-WildWatermelon-button text-white font-bold rounded-xl"
                            icon={
                              <span className="mr-1">
                                <CheckCircle width={17} height={16} />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UpcomingCampsListCard;
