import React from "react";

export const ClockVarient = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00065 1.33398C4.32465 1.33398 1.33398 4.32465 1.33398 8.00065C1.33398 11.6767 4.32465 14.6673 8.00065 14.6673C11.6767 14.6673 14.6673 11.6767 14.6673 8.00065C14.6673 4.32465 11.6767 1.33398 8.00065 1.33398ZM8.00065 13.334C5.05998 13.334 2.66732 10.9413 2.66732 8.00065C2.66732 5.05998 5.05998 2.66732 8.00065 2.66732C10.9413 2.66732 13.334 5.05998 13.334 8.00065C13.334 10.9413 10.9413 13.334 8.00065 13.334Z"
        fill="#EBA300"
      />
      <path
        d="M8.66732 4.66602H7.33398V8.66602H11.334V7.33268H8.66732V4.66602Z"
        fill="#EBA300"
      />
    </svg>
  );
};
