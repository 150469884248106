/**
 * Beneficiary List Components
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { Loader } from "../../../components/loader";
import { getAllState, selectState } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import MultipleTagsInput from "../../../components/multipleTagInput";
import DatePickerComponent from "../../../components/datePicker";
import { selectActiveUser, selectFilter, selectStateId } from "../selector";
import { setActiveUser, setFilter, setStateId } from "../slice";
import { FilterIcon } from "../../../assets/icons/FilterIcon";
import SelectDropDown from "../../../components/selectDropDown";

function BeneficiaryList(props) {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [isVisivleFilter, setIsVisivleFilter] = useState(false);
  // const [activeUserId, setActiveUserId] = useState(null);
  const activeUserId = useSelector(selectActiveUser);
  const filter = useSelector(selectFilter);
  const stateId = useSelector(selectStateId);
  const {
    users,
    beneficiaryStatus,
    completionTime,
    dateRange,
    currentStages,
    loading,
  } = props;
  const scrollableRef = useRef(null);

  const getState = () => {
    dispatch(
      getAllState({
        state_name: "",
        // iso_code: "",
        onSuccess: (props) => {
          console.log("All State loaded successfully");
        },
      })
    );
  };

  const setStartDate = (date) => {
    dispatch(setFilter({ startDate: date }));
    dispatch(setActiveUser(null));
  };

  const setEndDate = (date) => {
    dispatch(setFilter({ endDate: date }));
    dispatch(setActiveUser(null));
  };

  const setPinCode = (codes) => {
    dispatch(setFilter({ pinCode: codes }));
    dispatch(setActiveUser(null));
  };

  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptions = state?.map((item) => ({
    label: item?.state_name,
    value: item?._id,
  }));

  useEffect(() => {
    getState();
    scrollableRef.current.scrollTo(0, 0);
  }, [users]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Verified":
        return "text-warning-success";
      case "Rejected":
        return "text-warning-danger";
      case "Pending":
        return "text-warning-hold";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="flex flex-col h-full w-[42%] max-h-[1530px] ">
        {/* Search and Filter */}
        <div
          className={`container p-0 mx-auto flex justify-between items-center xl:mb-4 mb-2 flex-col`}
        >
          <div className="container flex justify-between items-center flex-row space-x-4">
            {/* Search Bar */}
            <div className="relative h-[40px] rounded-xl w-full">
              <div className="absolute inset-y-0 start-0 flex items-center xl:ps-3 ps-2 pointer-events-none h-full ">
                <SearchIcon width={24} height={24} />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full h-full xl:p-4 p-2 xl:ps-10 ps-10 xl:text-base md:text-sm text-base text-gray-tone-100 rounded-xl search-bar"
                placeholder="Search By Mobile Number/Name/Reg No."
                onChange={(event) => {
                  props.setSearchQuery(event.target.value);
                }}
              />
            </div>
            <div
              className="border w- h-full bg-white rounded-lg border-[#E8E8E8] flex justify-center items-center px-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                if (isVisivleFilter) {
                  setIsVisivleFilter(false);
                } else {
                  setIsVisivleFilter(true);
                }
              }}
            >
              <div>
                <FilterIcon width={20} height={20} />
              </div>
            </div>
          </div>

          {/* Filter Dropdown */}
          {isVisivleFilter && (
            <>
              <div className="w-full flex flex-row xl:space-x-4 space-x-2 xl:py-4 py-2">
                <div className="flex-1 w-full">
                  <SelectDropDown
                    options={stateOptions}
                    value={stateOptions.find(
                      (option) => option.value === stateId
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        dispatch(setStateId(selectedOption.value));
                      } else {
                        dispatch(setStateId(""));
                      }
                      dispatch(setActiveUser(null));
                    }}
                    placeholder={"State"}
                    isSearchable={true}
                    isClearable={true}
                  />
                </div>
                {dateRange && (
                  <div className="flex-1 w-full">
                    <DatePickerComponent
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      placeholderText="Regisration Date Range"
                      isClearable={true}
                      dateFormat="dd MMM yy"
                    />
                  </div>
                )}
              </div>
              <div className="w-full">
                <MultipleTagsInput
                  name="pinCode"
                  placeHolder="Enter Pincode"
                  value={filter.pinCode}
                  onChange={setPinCode}
                />
              </div>
            </>
          )}
        </div>

        {/* User List */}
        <div
          className="flex flex-col items-center justify-center bg-white p-0 m-0  rounded-xl shadow-sm h-full overflow-y-scroll scrollbar-hide border border-[#DDF1FE]"
          ref={scrollableRef}
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="container bg-white  rounded-xl shadow-sm h-full overflow-y-scroll scrollbar-hide border border-[#DDF1FE]">
              {users.length > 0 ? (
                users?.map((user, index) => (
                  <div
                    key={index}
                    className="container  border-b border-gray-border-100 hover:bg-customBlue-mainBackground"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setActiveUser(user?._id));
                      props.getBeneficiaryDetailByUserId(user?._id);
                    }}
                  >
                    <div
                      className={`container xl:p-4 p-2 flex items-center p-0 hover:bg-customBlue-mainBackground cursor-pointer ${
                        activeUserId === user._id ? "bg-[#DDF1FE]" : "bg-white"
                      }`}
                    >
                      {/* User Image */}
                      <div className="flex rounded-xl">
                        <img
                          src={user?.profile_picture}
                          alt=""
                          className="border-1 border-white rounded-xl w-[48px] h-[48px]"
                        />
                      </div>

                      {/* User Information */}
                      <div className="container pl-4 flex flex-col xl:space-y-2.5 space-y-1 w-full">
                        <div className="flex flex-1 justify-between w-full items-center">
                          <div className="font-bold text-sm text-black">
                            {user?.name}{" "}
                            <span className="text-gray-500 font-bold">
                              {" "}
                              <span className="text-gray-tone-divider">
                                |
                              </span>{" "}
                              Age:
                            </span>{" "}
                            {user?.age}; {user?.gender}
                          </div>
                          {/* Completion time */}
                          {completionTime && (
                            <div className="flex items-center font-inter gap-0.5 text-warning-success font-semibold text-right ">
                              <span className="text-gray-tone-400 text-xs">
                                Completion time:
                              </span>{" "}
                              <span className="font-medium text-[#00A638] xl:text-sm text-xs">
                                12:32 AM
                              </span>
                            </div>
                          )}

                          {/* Beneficiary status */}
                          {beneficiaryStatus && (
                            <div className="flex items-center font-inter gap-0.5 text-warning-success font-semibold text-right ">
                              <span
                                className={`text-[32px] font-extrabold line-h-0 ${getStatusColor(
                                  user?.status
                                )}`}
                              >
                                &bull;
                              </span>{" "}
                              <span
                                className={`text-xs font-[500] ${getStatusColor(
                                  user?.status
                                )}`}
                              >
                                {" "}
                                {user?.status}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-1 items-center justify-between w-full">
                          <div className="text-gray-tone-400 xl:text-sm text-xs">
                            {user?.disability_type_id?.name}
                          </div>
                          {currentStages && (
                            <div className="text-right font-inter leading-tight">
                              <span className="text-gray-tone-400 text-xs">
                                Current Stage :
                              </span>{" "}
                              <span className="font-medium text-black text-xs">
                                {user?.currentStage}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row  justify-between w-full items-start">
                          <div className="flex-auto text-gray-tone-400 xl:text-sm text-xs">
                            {user?.district?.district_name},
                            {user?.state?.state_name}, Pincode -{" "}
                            {user?.pin_code}
                          </div>
                          <div className="text-gray-600 text-right font-bold xl:text-sm text-xs flex items-center whitespace-nowrap gap-[3px]">
                            <span className="font-semibold">Reg. No :</span>{" "}
                            {user?.registration_number}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  {" "}
                  No Record Beneficiaries{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BeneficiaryList;
