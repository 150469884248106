/**
 * View All Next Count Details Model
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { manipulateDateAndTime } from "../utils";
import { selectSearchBeneficiaryDetails } from "../pages/dashboard/selector";
import BeneficiaryVerificationPart from "../pages/beneficiary_management/components/beneficiaryVerificationForm";
import { AssessmentComponent } from "../pages/beneficiary_management/components/assessmentCard";
import {
  FabricationComponent,
  FabricationReadOnlyComponent,
} from "../pages/beneficiary_management/components/fabricationCard";
import { DistributionComponent } from "../pages/beneficiary_management/components/distributionCard";
import { Cross } from "../assets/icons/Cross";
import { searchBeneficiary } from "../pages/dashboard/slice";
import BeneficiaryDetailWithCurrentStatus from "../pages/beneficiary_management/components/beneficiaryDetailWithCurrentStatus";
import PersonalDetails from "../pages/beneficiary_management/components/personalDetails";
import BeneficiaryJourney from "../pages/beneficiary_management/components/beneficiaryJourney";
import BeneficiaryAllDetail from "../pages/beneficiary_management/components/beneficiaryAllDetail";

const BeneficiaryDetailAndJourneyDataModels = ({
  toggleModal,
  beneficiaryId,
}) => {
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);
  // const beneficiaryData = beneficiary || {};
  const {
    beneficiaryDetails: beneficiaryData = {},
    beneficiaryJourney,
    campData,
  } = useSelector(selectSearchBeneficiaryDetails) || {};
  const dispatch = useDispatch();

  const initialValues = {
    search: "",
  };

  // handel journey stpes
  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });
  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.assessments?.data[0] // Correct reference here
    )?.status_id?.status_name;

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0] // Set the first item as the initial value
    )?.status_id?.status_name; // Access status_name after reduce

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={beneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus: "complete",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentComponent
          campData={campData?.assessment}
          beneficiary_id={beneficiaryData?._id}
        />
      ),
      campComplete: ``,
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled: campData?.assessment ? false : true,
      lastStatus:
        assessment_last_Status === "assessement_complete"
          ? "complete"
          : assessment_last_Status === "assessment_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: (
        <FabricationReadOnlyComponent
          // beneficiary_id={beneficiaryData?._id}
          // status={allStatus}
          // stageIds={stageIds}
          // fabrication_data={fabrication_data || {}}
          fabricationDisabled={true}
        />
      ),
      campScheduled: "",
      campComplete: ``,
      campNotSchedled: `Not yet scheduled`,
      disabled:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? false
          : true,
      lastStatus: "in_progress",
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: <DistributionComponent />,
      campComplete: ``,
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      disabled: campData?.distribution ? false : true,
      lastStatus:
        distribution_last_Status === "distribution_complete"
          ? "complete"
          : distribution_last_Status === "distribution_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (beneficiaryData?._id) {
      const result = manipulateDateAndTime(beneficiaryData?.createdAt);
      setDateTime(result);
    }
  }, [beneficiaryData]);

  useEffect(() => {
    if (beneficiaryId) {
      //   dispatch(
      //     searchBeneficiary({
      //       search: beneficiaryId,
      //       onSuccess: (props) => {
      //         // console.log(props?.data);
      //         setBeneficiaryDetailToggle(true);
      //       },
      //     })
      //   );
    }
  }, [beneficiaryId]);

  return (
    <div className="fixed h-full inset-0 z-50 flex justify-end">
      <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
      <div
        data-aos-easing="linear"
        data-aos="fade-left"
        className="flex flex-col relative w-[55%] bg-white h-full shadow-lg overflow-y-auto scrollbar-hide"
      >
        <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
          <h2 className="text-xl font-bold">Beneficiary Details</h2>
          <button onClick={toggleModal} className="text-xl font-semibold">
            <Cross width={32} height={32} />
          </button>
        </div>
        {/* Modal content */}
        <div className="w-full flex flex-col flex-auto justify-start space-y-4 py-6 px-6">
          {/* Beneficiary Details */}
          <div className="flex justify-center items-center w-full overflow-y-auto scrollbar-hide px-0">
            {beneficiaryDetailToggle &&
            Object.keys(beneficiaryData)?.length > 0 ? (
              beneficiaryData?.status === "Verified" ? (
                <div className="container p-0 h-full self-end w-full ">
                  <div
                    className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-4 py-4 max-w-full w-full rounded-xl"
                    // ref={scrollableRef}
                  >
                    {/* Beneficiary Detail */}
                    <BeneficiaryDetailWithCurrentStatus
                      userData={beneficiaryData}
                    />

                    {/* Personal Detail */}
                    <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                      <PersonalDetails userData={beneficiaryData} />
                    </div>
                    {/* Beneficiary Journey */}
                    <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                      <BeneficiaryJourney
                        beneficiary={beneficiaryData}
                        beneficiaryJourney={beneficiaryJourney}
                        steps={steps}
                        setExpandedSteps={setExpandedSteps}
                        expandedSteps={expandedSteps}
                        toggleStep={toggleStep}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <BeneficiaryAllDetail
                  singleBeneficiary={beneficiaryData}
                  data={beneficiaryData}
                  bySearch={true}
                />
              )
            ) : (
              <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-full bg-white rounded-lg"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { BeneficiaryDetailAndJourneyDataModels };
