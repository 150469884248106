/**
 * Root Reducer
 * Combine all reducer to create root reducer
 * @format
 */

import { combineReducers } from "@reduxjs/toolkit";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { commonReducer } from "../pages/common";
import { userManagementReducer } from "../pages/user_management/slice";
import { roleManagementReducer } from "../pages/role_management/slice";
import { permissionManagementReducer } from "../pages/permissions_management/slice";
import { accessLevelManagementReducer } from "../pages/access_level_management/slice";
import { beneficiaryDetailManagementReducer } from "../pages/beneficiary_management/slice";
import { dashboardManagementReducer } from "../pages/dashboard/slice";
import { campCenterManagementReducer } from "../pages/camp_center_management";
import { scheduleCampManagementReducer } from "../pages/camp_management/slice";
import { campOverviewManagementReducer } from "../pages/camp_overview_management/slice";
import { fabricationManagementReducer } from "../pages/fabrication_management/slice";
// import { authReducer } from "@/pages/auth/slice"; // Uncomment and replace with actual path when adding other reducers

// Configuration for persisting the root reducer
const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [],
  blacklist: ["common"],
};

// Configuration for persisting the common reducer
const commonPersistConfig = {
  key: "common",
  storage,
  blacklist: ["loaders"], // Ensure this matches the actual key in your state
};

// Combine your reducers here
const appReducer = combineReducers({
  common: persistReducer(commonPersistConfig, commonReducer),
  userManagementReducer,
  roleManagementReducer,
  permissionManagementReducer,
  accessLevelManagementReducer,
  beneficiaryDetailManagementReducer,
  dashboardManagementReducer,
  campCenterManagementReducer,
  scheduleCampManagementReducer,
  campOverviewManagementReducer,
  fabricationManagementReducer,
  // Add other reducers here
});

// Root reducer with optional state and action parameters
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// Create a persisted version of the root reducer
const persistRootReducer = persistReducer(rootPersistConfig, rootReducer);

// TypeScript type for RootState, only needed if using TypeScript
// export type RootState = ReturnType<typeof appReducer>;

export { persistRootReducer };
