import React from "react";

export const CheckCircleGreen = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#D6FFE4" />
      <g clip-path="url(#clip0_2080_1792)">
        <path
          d="M19.1068 11.5357V12.149C19.106 13.5866 18.6404 14.9854 17.7797 16.1369C16.9189 17.2883 15.709 18.1307 14.3303 18.5383C12.9517 18.9459 11.4783 18.8969 10.1298 18.3987C8.78122 17.9005 7.62987 16.9797 6.84741 15.7737C6.06495 14.5677 5.6933 13.141 5.78789 11.7065C5.88248 10.272 6.43824 8.90655 7.37229 7.81371C8.30634 6.72088 9.56863 5.95925 10.9709 5.64243C12.3732 5.3256 13.8403 5.47056 15.1534 6.05567"
          stroke="#007C2A"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.1061 6.81445L12.4395 13.4878L10.4395 11.4878"
          stroke="#007C2A"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2080_1792">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4.43945 4.14844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
