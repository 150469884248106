/**
 * Multiple Dropdown Component
 * @format
 */

import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";

const MultiSelectDropDown = ({
  name,
  options,
  displayValue,
  onRemove,
  onSelect,
  touched,
  errors,
  placeholder,
  selectedValues,
  showCheckbox,
  showArrow,
  disabled,
}) => {
  return (
    <div className="w-[100%]">
      <Multiselect
        options={options}
        displayValue={displayValue || "label"}
        onSelect={onSelect}
        onRemove={onRemove}
        selectedValues={selectedValues}
        showCheckbox={showCheckbox}
        showArrow={showArrow}
        placeholder={placeholder || "Select"}
        disable={disabled}
        style={{
          multiselectContainer: {
            padding: "0px",
            border: "2px solid #B0E0FF",
            borderRadius: "12px",
          },
          searchBox: {
            border: "none",
            height: "44px",
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            backgroundColor: disabled ? "#f0f0f0" : "white",
          },
          chips: {
            backgroundColor: "#B0E0FF",
            color: "black",
          },
        }}
      />
    </div>
  );
};

export default MultiSelectDropDown;
